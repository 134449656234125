/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any };
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: any; output: any };
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: { input: any; output: any };
  IntegrationConfig: { input: any; output: any };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any };
};

export type Action = {
  __typename?: 'Action';
  /**  Action  */
  action: Scalars['String']['output'];
  /**  Description of the Action  */
  description: Scalars['String']['output'];
  /**  Action  */
  identifier: Scalars['String']['output'];
  /**  If the Function is externally executable  */
  manualExecution: Scalars['Boolean']['output'];
  /**  The Parameters the Action Requires  */
  parameters?: Maybe<Array<Maybe<Parameter>>>;
  /**  The action type  */
  type: Scalars['String']['output'];
};

/** A human readable notable event. */
export type ActivityFeedEntryType = Node & {
  __typename?: 'ActivityFeedEntryType';
  activityFeedLinksCount?: Maybe<Scalars['Int']['output']>;
  /** The category of event, should match the name of the type of objects linked to this entry. */
  category: Scalars['String']['output'];
  /** Represents the time at which the object was originally created */
  createdTimestamp: Scalars['DateTime']['output'];
  /** Used to show delta in scores. */
  delta?: Maybe<Scalars['Int']['output']>;
  /** A string used to group related activity into a single entry. */
  groupBy?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The integration associated with this activity. */
  integration?: Maybe<IntegrationType>;
  /** The activity feed associated with this link. */
  links: ActivityFeedLinkTypeConnection;
  /** A field used to construct a dynamic short description for this activity, in the following format '$username $prefix $link(s) $suffix $integration'. */
  prefix: Scalars['String']['output'];
  /** The score value for scores only. */
  score?: Maybe<Scalars['Int']['output']>;
  /** A way of categorizing entries by what scores they affect. */
  scoreGroup?: Maybe<Scalars['String']['output']>;
  /** A field used to construct a dynamic short description for this activity, in the following format '$username $prefix $link(s) $suffix $integration'. */
  suffix: Scalars['String']['output'];
  /** A short description of this activity. */
  title: Scalars['String']['output'];
  /** The user the that performed this activity. */
  user?: Maybe<UserType>;
};

/** A human readable notable event. */
export type ActivityFeedEntryTypeLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityFeedEntryTypeConnection = {
  __typename?: 'ActivityFeedEntryTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityFeedEntryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ActivityFeedEntryType` and its cursor. */
export type ActivityFeedEntryTypeEdge = {
  __typename?: 'ActivityFeedEntryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityFeedEntryType>;
};

/** A relationship between an event and a STIX object. */
export type ActivityFeedLinkType = Node & {
  __typename?: 'ActivityFeedLinkType';
  /** The activity feed associated with this link. */
  activityFeedEntry: ActivityFeedEntryType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<StixObject>;
  /** The ID to the model that is linked. */
  linkId: Scalars['String']['output'];
  /** The type of object linked, should be the model name. */
  type: Scalars['String']['output'];
};

export type ActivityFeedLinkTypeConnection = {
  __typename?: 'ActivityFeedLinkTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityFeedLinkTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ActivityFeedLinkType` and its cursor. */
export type ActivityFeedLinkTypeEdge = {
  __typename?: 'ActivityFeedLinkTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityFeedLinkType>;
};

export type AddGroupMembership = {
  groupIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type AddLabelToAsset = {
  __typename?: 'AddLabelToAsset';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type AddLabelToAssetInput = {
  /** The asset to apply the label to. */
  assetId: Scalars['String']['input'];
  /** The label to be applied. */
  label: Scalars['String']['input'];
};

export type AddLabelToIdentity = {
  __typename?: 'AddLabelToIdentity';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type AddLabelToIdentityInput = {
  /** The identity to apply the label to. */
  identityId: Scalars['String']['input'];
  /** The label to be applied. */
  label: Scalars['String']['input'];
};

/** IntegrationTag an integration. */
export type AddTagToIntegration = {
  __typename?: 'AddTagToIntegration';
  integration?: Maybe<IntegrationType>;
};

export type AddTagToIntegrationInput = {
  /** ID of the integration to tag. */
  integrationId: Scalars['ID']['input'];
  /** ID of the tag to use. */
  tagId: Scalars['ID']['input'];
};

/** Add multiple tags to an integration, any tag not found will be created. */
export type AddTagsToIntegration = {
  __typename?: 'AddTagsToIntegration';
  integration?: Maybe<IntegrationType>;
};

export type AddTagsToIntegrationInput = {
  /** ID of the integration to tag. */
  integrationId: Scalars['ID']['input'];
  /** List of case sensitive tag names, new names will automatically created into tags. */
  names: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Logs that help administrators troubleshoot issues with Interpres and attribute activity to users. */
export type AdminLogType = Node & {
  __typename?: 'AdminLogType';
  component?: Maybe<Scalars['String']['output']>;
  createdTimestamp: Scalars['DateTime']['output'];
  /** JSON string containing additional data about the log entry. */
  data?: Maybe<Scalars['GenericScalar']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  level: AuthenticationAdminLogLevelChoices;
  message: Scalars['String']['output'];
  /** User matching the username in the log, user will be None if the user no longer exists. */
  user?: Maybe<UserType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AdminLogTypeConnection = {
  __typename?: 'AdminLogTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdminLogTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AdminLogType` and its cursor. */
export type AdminLogTypeEdge = {
  __typename?: 'AdminLogTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AdminLogType>;
};

/** An object representing a product that is affected by a vulnerability. */
export type AffectedProductType = Node & {
  __typename?: 'AffectedProductType';
  /** The default status of the product. */
  defaultStatus?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The affected product. */
  product: Scalars['String']['output'];
  /** The program files of the affected product. This can be a list stored as a string. */
  programFiles?: Maybe<Scalars['String']['output']>;
  /** The repository of the affected product. */
  repo?: Maybe<Scalars['String']['output']>;
  /** The vendor of the affected product. */
  vendor: Scalars['String']['output'];
  versions: AffectedProductVersionTypeConnection;
};

/** An object representing a product that is affected by a vulnerability. */
export type AffectedProductTypeVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AffectedProductTypeConnection = {
  __typename?: 'AffectedProductTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AffectedProductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AffectedProductType` and its cursor. */
export type AffectedProductTypeEdge = {
  __typename?: 'AffectedProductTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AffectedProductType>;
};

/** An object representing a version of an affected product. */
export type AffectedProductVersionType = Node & {
  __typename?: 'AffectedProductVersionType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The inclusive upper limit of the affected version range. */
  lessThanOrEqual?: Maybe<Scalars['String']['output']>;
  /** The status of the version. */
  status?: Maybe<Scalars['String']['output']>;
  /** The version of the affected product. */
  version: Scalars['String']['output'];
  /** The type of versioning used (e.g., semver). */
  versionType?: Maybe<Scalars['String']['output']>;
};

export type AffectedProductVersionTypeConnection = {
  __typename?: 'AffectedProductVersionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AffectedProductVersionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AffectedProductVersionType` and its cursor. */
export type AffectedProductVersionTypeEdge = {
  __typename?: 'AffectedProductVersionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AffectedProductVersionType>;
};

export type Application = {
  __typename?: 'Application';
  /**  The Actions an app supports  */
  actions: Array<Maybe<Action>>;
  /** The common name for the product. */
  appId: Scalars['String']['output'];
  /** A short description about the product. */
  description?: Maybe<Scalars['String']['output']>;
  /** The common name for the product. */
  name: Scalars['String']['output'];
  /** Whether the app has notional actions. */
  notionalSupport: Scalars['Boolean']['output'];
  /** The apps package name. */
  packageName: Scalars['String']['output'];
  /**  The Parameters the App Requires  */
  parameters?: Maybe<Array<Maybe<Parameter>>>;
  /** The type or category of the product. */
  productType: Scalars['String']['output'];
  /** The type or category of the product. */
  productTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** The name of the vendor of the product this integration is used for. */
  productVendor: Scalars['String']['output'];
  /** The apps version. */
  version: Scalars['String']['output'];
};

export type AssetApplicationConnection = {
  __typename?: 'AssetApplicationConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetApplicationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetApplication` and its cursor. */
export type AssetApplicationEdge = {
  __typename?: 'AssetApplicationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetApplicationType>;
};

/** An object representing an asset's application. */
export type AssetApplicationType = Node & {
  __typename?: 'AssetApplicationType';
  /** Represents the time at which the object was originally created. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The asset identified to have this application. */
  integrationAsset: IntegrationAssetType;
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type AssetApplicationTypeConnection = {
  __typename?: 'AssetApplicationTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetApplicationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetApplicationType` and its cursor. */
export type AssetApplicationTypeEdge = {
  __typename?: 'AssetApplicationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetApplicationType>;
};

/** Asset count summary data. */
export type AssetCountsType = {
  __typename?: 'AssetCountsType';
  /** Total number of assets without any vulnerabilities. */
  isNotVulnerableCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of assets with at least one vulnerability. */
  isVulnerableCount?: Maybe<Scalars['Int']['output']>;
  /** A list of platforms with their associated vulnerability counts. */
  platformCounts?: Maybe<Array<Maybe<AssetPlatformCountsType>>>;
  /** Total aggregated assets objects. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssetIpConnection = {
  __typename?: 'AssetIPConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetIpEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetIP` and its cursor. */
export type AssetIpEdge = {
  __typename?: 'AssetIPEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetIpType>;
};

/** An object representing an asset's IP address. */
export type AssetIpType = Node & {
  __typename?: 'AssetIPType';
  /** Represents the time at which the object was originally created. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  gateway?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The asset to whom this IP belongs. */
  integrationAsset: IntegrationAssetType;
  ipv4Address?: Maybe<Scalars['String']['output']>;
  ipv6Address?: Maybe<Scalars['String']['output']>;
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  subnetMask?: Maybe<Scalars['String']['output']>;
};

export type AssetIpTypeConnection = {
  __typename?: 'AssetIPTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetIpTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetIPType` and its cursor. */
export type AssetIpTypeEdge = {
  __typename?: 'AssetIPTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetIpType>;
};

/** A label describing a feature of this asset. */
export type AssetLabelType = Node & {
  __typename?: 'AssetLabelType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  origin: AssetsAssetLabelOriginChoices;
};

export type AssetLabelTypeConnection = {
  __typename?: 'AssetLabelTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetLabelTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetLabelType` and its cursor. */
export type AssetLabelTypeEdge = {
  __typename?: 'AssetLabelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetLabelType>;
};

/** Asset counts by a single platform. */
export type AssetPlatformCountsType = {
  __typename?: 'AssetPlatformCountsType';
  /** Total number of assets of this platform without any vulnerabilities. */
  isNotVulnerableCount?: Maybe<Scalars['Int']['output']>;
  /** Total number of assets of this platform with at least one vulnerability. */
  isVulnerableCount?: Maybe<Scalars['Int']['output']>;
  /** Platform name. */
  name?: Maybe<Scalars['String']['output']>;
  /** Total aggregated asset objects of this platform. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An object representing a asset. */
export type AssetType = Node &
  StixObject & {
    __typename?: 'AssetType';
    applications?: Maybe<AssetApplicationConnection>;
    applicationsCount?: Maybe<Scalars['Int']['output']>;
    /** The number of detections, preventions, and controls an organization has protecting this asset. */
    content: Scalars['Int']['output'];
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** If the asset has been flagged as critical to an organization. */
    critical: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** A value representing how easy is to exploit this asset based on their threat profile. A higher value is easier to exploit. */
    exposure: Scalars['Int']['output'];
    fleetExposureScore?: Maybe<Scalars['Int']['output']>;
    /** The hostname of the asset. */
    hostname?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    integrationName?: Maybe<Scalars['String']['output']>;
    integrationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    ipCount?: Maybe<Scalars['Int']['output']>;
    ips?: Maybe<AssetIpConnection>;
    labels: AssetLabelTypeConnection;
    /** The last time the asset was seen by the integration. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The Operating System running on the asset. */
    operatingSystem?: Maybe<Scalars['String']['output']>;
    platformName?: Maybe<Scalars['String']['output']>;
    platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** This asset's platforms. */
    platforms: PlatformTypeConnection;
    platformsCount?: Maybe<Scalars['Int']['output']>;
    policyNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** A value representing how much an organization should prioritize this asset based on their threat profile. This value does not take existing protections into consideration. */
    priority: Scalars['Int']['output'];
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    uniqueApplicationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    uniqueIpAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    uniqueMacAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    vulnerabilities?: Maybe<PrioritizedVulnerabilityConnection>;
    vulnerabilityCveIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** An object representing a asset. */
export type AssetTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a asset. */
export type AssetTypeIpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a asset. */
export type AssetTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a asset. */
export type AssetTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a asset. */
export type AssetTypeVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetTypeConnection = {
  __typename?: 'AssetTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetType` and its cursor. */
export type AssetTypeEdge = {
  __typename?: 'AssetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetType>;
};

/** An object representing a known vulnerability on an asset. */
export type AssetVulnerabilityType = Node & {
  __typename?: 'AssetVulnerabilityType';
  /** Represents the time at which this asset vulnerability object was originally created. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The first time this vulnerability was seen on this asset. */
  firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The integration that identified this vulnerability. */
  integration?: Maybe<IntegrationType>;
  /** The asset with the identified vulnerability. */
  integrationAsset: IntegrationAssetType;
  /** The last time this vulnerability was seen on this asset. */
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The identified vulnerability. */
  vulnerability: VulnerabilityType;
};

export type AssetVulnerabilityTypeConnection = {
  __typename?: 'AssetVulnerabilityTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetVulnerabilityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AssetVulnerabilityType` and its cursor. */
export type AssetVulnerabilityTypeEdge = {
  __typename?: 'AssetVulnerabilityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AssetVulnerabilityType>;
};

/** An enumeration. */
export enum AssetsAssetLabelOriginChoices {
  /** integration */
  Integration = 'INTEGRATION',
  /** system */
  System = 'SYSTEM',
  /** user */
  User = 'USER'
}

export type AssetsThreatSummary = {
  __typename?: 'AssetsThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
  severityDistribution?: Maybe<Array<Maybe<SeverityThreatSummary>>>;
};

/** An enumeration. */
export enum AuthenticationAdminLogLevelChoices {
  /** Critical */
  Critical = 'CRITICAL',
  /** Debug */
  Debug = 'DEBUG',
  /** Error */
  Error = 'ERROR',
  /** Info */
  Info = 'INFO',
  /** Warning */
  Warning = 'WARNING'
}

/** An alternative name for a piece of campaign. */
export type CampaignAliasType = {
  __typename?: 'CampaignAliasType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An object representing a campaign. */
export type CampaignType = Node &
  StixObject & {
    __typename?: 'CampaignType';
    aliasNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    aliases: Array<CampaignAliasType>;
    assetsCount?: Maybe<Scalars['Int']['output']>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The domains encompassed within this campaign. */
    domains: DomainTypeConnection;
    /** Represents the time at which this campaign activity was first observed, corresponds to the 'x_mitre_first_seen_citation' field. */
    firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    /** Represents the latest observed activity from this campaign, corresponds to the 'x_mitre_last_seen_citation' field. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The origin of the report, e.g. 'MITRE', 'CISA', 'Internal' */
    origin: Scalars['String']['output'];
    referenceUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** The software or malware contained in this campaign. */
    software: SoftwareTypeConnection;
    softwareCount?: Maybe<Scalars['Int']['output']>;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** The techniques contained in this campaign. */
    techniques: TechniqueTypeConnection;
    techniquesCount?: Maybe<Scalars['Int']['output']>;
    /** The threat groups contained in this campaign. */
    threatGroups: ThreatGroupTypeConnection;
    threatGroupsCount?: Maybe<Scalars['Int']['output']>;
    /** The type of report, e.g. 'threat report', 'campaign' */
    type: Scalars['String']['output'];
    /** Determines if the object should be overwritten on change. */
    update: Scalars['Boolean']['output'];
    /** Known vulnerabilities/CVEs used during this campaign. */
    vulnerabilities: VulnerabilityTypeConnection;
    vulnerabilitiesCount?: Maybe<Scalars['Int']['output']>;
    vulnerabilityCveIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** An object representing a campaign. */
export type CampaignTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a campaign. */
export type CampaignTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a campaign. */
export type CampaignTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a campaign. */
export type CampaignTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a campaign. */
export type CampaignTypeVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignTypeConnection = {
  __typename?: 'CampaignTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CampaignType` and its cursor. */
export type CampaignTypeEdge = {
  __typename?: 'CampaignTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CampaignType>;
};

export type CampaignsThreatSummary = {
  __typename?: 'CampaignsThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
};

/** A conversation with AI */
export type ChatBotHistoryType = Node & {
  __typename?: 'ChatBotHistoryType';
  /** The answer received from the ai. */
  answer: Scalars['String']['output'];
  createdTimestamp: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The question asked to the ai. */
  question: Scalars['String']['output'];
};

export type ChatBotHistoryTypeConnection = {
  __typename?: 'ChatBotHistoryTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChatBotHistoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ChatBotHistoryType` and its cursor. */
export type ChatBotHistoryTypeEdge = {
  __typename?: 'ChatBotHistoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ChatBotHistoryType>;
};

export type ChildTenant = {
  __typename?: 'ChildTenant';
  emailDomains?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

/** Clears manually uploaded data. */
export type ClearManualData = {
  __typename?: 'ClearManualData';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type ClearManualDataInput = {
  dataType: ManualDataType;
  integrationUuid: Scalars['String']['input'];
};

/** An object representing a control. */
export type ControlType = Node &
  StixObject & {
    __typename?: 'ControlType';
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The origin of the control, e.g. 'NIST' */
    origin: Scalars['String']['output'];
    /** A unique ID for the control from its originator. */
    referenceId?: Maybe<Scalars['String']['output']>;
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** The techniques this control helps mitigate. */
    techniques: TechniqueTypeConnection;
    techniquesCount?: Maybe<Scalars['Int']['output']>;
    /** Determines if the object should be overwritten on change. */
    update: Scalars['Boolean']['output'];
  };

/** An object representing a control. */
export type ControlTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ControlTypeConnection = {
  __typename?: 'ControlTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ControlTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ControlType` and its cursor. */
export type ControlTypeEdge = {
  __typename?: 'ControlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ControlType>;
};

/** Creates a copy of an information model with a new name. */
export type CopyInformationModel = {
  __typename?: 'CopyInformationModel';
  informationModel?: Maybe<InformationModelType>;
};

export type CopyInformationModelInput = {
  /** ID of the information model to copy. */
  informationModelId: Scalars['ID']['input'];
  /** Name of the new information model. */
  name: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Creates a ChatBotHistory */
export type CreateChatBotHistory = {
  __typename?: 'CreateChatBotHistory';
  chatBotHistory?: Maybe<ChatBotHistoryType>;
};

export type CreateChildTenant = {
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type CreateCollectorEntity = {
  name: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateCollectorEntityResponse = {
  __typename?: 'CreateCollectorEntityResponse';
  entityId: Scalars['String']['output'];
  entitySecret: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

/** Add relationship to detection. Note, relationships can only be disabled not deleted after creation. */
export type CreateDetectionMapping = {
  __typename?: 'CreateDetectionMapping';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type CreateDetectionMappingInput = {
  /** ID of the integration to tag. */
  detectionId: Scalars['ID']['input'];
  /** ID of the object being related to this detection. */
  relationshipId: Scalars['ID']['input'];
};

export type CreateGroup = {
  name: Scalars['String']['input'];
  roleIds: Array<Scalars['String']['input']>;
};

/** Creates an information model. */
export type CreateInformationModel = {
  __typename?: 'CreateInformationModel';
  informationModel?: Maybe<InformationModelType>;
};

/** Creates an information model alias. */
export type CreateInformationModelAlias = {
  __typename?: 'CreateInformationModelAlias';
  alias?: Maybe<InformationModelAliasType>;
};

export type CreateInformationModelAliasInput = {
  /** Name of the field in Interpres. */
  fieldName: Scalars['String']['input'];
  /** ID of the information model to add the alias to. */
  informationModelId: Scalars['ID']['input'];
  /** Name of the corresponding field in the integration. */
  newFieldName: Scalars['String']['input'];
};

/** Creates an integration. */
export type CreateIntegration = {
  __typename?: 'CreateIntegration';
  integration?: Maybe<IntegrationType>;
};

export type CreateIntegrationConfig = {
  appId: Scalars['String']['input'];
  config: Scalars['IntegrationConfig']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateIntegrationInputType = {
  /** The ID of the Interpres App used to perform the integration, should be used instead of the integration type id. */
  appId?: InputMaybe<Scalars['ID']['input']>;
  /** The arguments based on the integration types parameters and the intervals for the reoccurring actions of the integration. */
  config: Scalars['GenericScalar']['input'];
  /** A brief description of the integration. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Enables this integration. */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Environment to run the integration. */
  environment?: InputMaybe<Scalars['String']['input']>;
  /** The information model used for this integration when identifying telemetry. */
  informationModelId?: InputMaybe<Scalars['ID']['input']>;
  /** The type of integration, deprecated for app_id. */
  integrationTypeId?: InputMaybe<Scalars['ID']['input']>;
  modes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Name of the integration. */
  name: Scalars['String']['input'];
};

/** Creates an integration tag. */
export type CreateIntegrationTag = {
  __typename?: 'CreateIntegrationTag';
  tag?: Maybe<IntegrationTagType>;
};

export type CreateSchedule = {
  /**  The Action to run  */
  actionIdentifier: Scalars['String']['input'];
  /**  where to run it  */
  environment?: InputMaybe<Scalars['String']['input']>;
  /**  How often to run it  */
  fequency: ScheduleFrequency;
  /** The integration id */
  integrationId: Scalars['String']['input'];
  /** The schedule priority */
  priority?: InputMaybe<Scalars['String']['input']>;
  /**  App Version  */
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a threat profile. */
export type CreateThreatProfile = {
  __typename?: 'CreateThreatProfile';
  threatProfile?: Maybe<ThreatProfileType>;
};

/** Creates a service ticket in ServiceNow */
export type CreateTicket = {
  __typename?: 'CreateTicket';
  ticket?: Maybe<CreateTicketResponse>;
};

export type CreateTicketInput = {
  body: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateTicketResponse = {
  __typename?: 'CreateTicketResponse';
  body?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CreateToken = {
  __typename?: 'CreateToken';
  token?: Maybe<TokenType>;
};

export type CreateUser = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  forceReset?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type DailyScoreEntry = {
  __typename?: 'DailyScoreEntry';
  /** The date string in YYYY-MM-DD format. */
  date?: Maybe<Scalars['String']['output']>;
  /** The last defense posture score for the given date. */
  defensePostureScore?: Maybe<Scalars['Int']['output']>;
  /** The last exposure score for the given date. */
  exposureScore?: Maybe<Scalars['Int']['output']>;
  /** The last fleet exposure score for the given date. */
  fleetExposureScore?: Maybe<Scalars['Int']['output']>;
  /** The last threat exposure score for the given date. */
  threatExposureScore?: Maybe<Scalars['Int']['output']>;
};

export type DailyScoreHistory = {
  __typename?: 'DailyScoreHistory';
  /** Number of entries (days) in this history. */
  count?: Maybe<Scalars['Int']['output']>;
  /** A list of score changes per day. Scores are based on the last score change for that date. */
  entries?: Maybe<Array<Maybe<DailyScoreEntry>>>;
};

/** Cached trend data. */
export type DailyTrendDataType = Node & {
  __typename?: 'DailyTrendDataType';
  /** Date of the trend data. */
  date: Scalars['Date']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Unique key for the name of the value being trended. */
  name: Scalars['String']['output'];
  /** The value of the data. */
  value: Scalars['Float']['output'];
  /** The value in String form. */
  valueString: Scalars['String']['output'];
};

export type DailyTrendDataTypeConnection = {
  __typename?: 'DailyTrendDataTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DailyTrendDataTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DailyTrendDataType` and its cursor. */
export type DailyTrendDataTypeEdge = {
  __typename?: 'DailyTrendDataTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DailyTrendDataType>;
};

export type DailyTrendEntry = {
  __typename?: 'DailyTrendEntry';
  /** The date string in YYYY-MM-DD format. */
  date?: Maybe<Scalars['String']['output']>;
  /** The value of the given entry. */
  value?: Maybe<Scalars['Int']['output']>;
};

export type DeactivateUser = {
  userId: Scalars['String']['input'];
};

/** The source of data. */
export type DefinitionSourceType = Node & {
  __typename?: 'DefinitionSourceType';
  /** A description of the data definition source. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The name of the data definition source. */
  name: Scalars['String']['output'];
  /** Whether the data is notional or not. */
  notional: Scalars['Boolean']['output'];
};

/** Deletes a given ChatBotHistory */
export type DeleteChatBotHistory = {
  __typename?: 'DeleteChatBotHistory';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type DeleteGroup = {
  groupId: Scalars['String']['input'];
};

/** Deletes an information model. */
export type DeleteInformationModel = {
  __typename?: 'DeleteInformationModel';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** Deletes an information model alias. */
export type DeleteInformationModelAlias = {
  __typename?: 'DeleteInformationModelAlias';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** Deletes an integration. */
export type DeleteIntegration = {
  integrationId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** Deletes a tag. */
export type DeleteIntegrationTag = {
  __typename?: 'DeleteIntegrationTag';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** Deletes a threat profile. */
export type DeleteThreatProfile = {
  __typename?: 'DeleteThreatProfile';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type DeleteToken = {
  __typename?: 'DeleteToken';
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUser = {
  userId: Scalars['String']['input'];
};

export type DeleteWorkerEntity = {
  entityId: Scalars['String']['input'];
};

/** A suggested mapping between a Detection and a piece of software. */
export type DetectionSoftwareSuggestionType = Node & {
  __typename?: 'DetectionSoftwareSuggestionType';
  /** The detection that the automapper is suggesting should be mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. After creation this value should only be changed by the user. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A score indicating the certainty of the suggestion. */
  score: Scalars['Int']['output'];
  /** A a piece of software the automapper suggest should be mapped to the detection. */
  software: SoftwareType;
};

/** User mapping between a Detection and a piece of software. */
export type DetectionSoftwareUserMappingType = Node & {
  __typename?: 'DetectionSoftwareUserMappingType';
  /** The detection that the user mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a piece of software created by a user. */
  software: SoftwareType;
};

/** Vendor mapping between a Detection and a piece of software. */
export type DetectionSoftwareVendorMappingType = Node & {
  __typename?: 'DetectionSoftwareVendorMappingType';
  /** The detection that the integration vendor mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents when this mapping was last modified. */
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a piece of software created by a vendor. */
  software: SoftwareType;
};

/** A suggested mapping between a Detection and an adversarial Technique. */
export type DetectionTechniqueSuggestionType = Node & {
  __typename?: 'DetectionTechniqueSuggestionType';
  /** The detection that the automapper is suggesting should be mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. After creation this value should only be changed by the user. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A score indicating the certainty of the suggestion. */
  score: Scalars['Int']['output'];
  /** A technique the automapper suggest should be mapped to the detection. */
  technique: TechniqueType;
};

export type DetectionTechniqueSuggestionTypeConnection = {
  __typename?: 'DetectionTechniqueSuggestionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DetectionTechniqueSuggestionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DetectionTechniqueSuggestionType` and its cursor. */
export type DetectionTechniqueSuggestionTypeEdge = {
  __typename?: 'DetectionTechniqueSuggestionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DetectionTechniqueSuggestionType>;
};

/** User mapping between a Detection and an adversarial Technique. */
export type DetectionTechniqueUserMappingType = Node & {
  __typename?: 'DetectionTechniqueUserMappingType';
  /** The detection that the user mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a technique created by a user. */
  technique: TechniqueType;
};

export type DetectionTechniqueUserMappingTypeConnection = {
  __typename?: 'DetectionTechniqueUserMappingTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DetectionTechniqueUserMappingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DetectionTechniqueUserMappingType` and its cursor. */
export type DetectionTechniqueUserMappingTypeEdge = {
  __typename?: 'DetectionTechniqueUserMappingTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DetectionTechniqueUserMappingType>;
};

/** Vendor mapping between a Detection and an adversarial Technique. */
export type DetectionTechniqueVendorMappingType = Node & {
  __typename?: 'DetectionTechniqueVendorMappingType';
  /** The detection that the integration vendor mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents when this mapping was last modified. */
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a technique created by a vendor. */
  technique: TechniqueType;
};

export type DetectionTechniqueVendorMappingTypeConnection = {
  __typename?: 'DetectionTechniqueVendorMappingTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DetectionTechniqueVendorMappingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DetectionTechniqueVendorMappingType` and its cursor. */
export type DetectionTechniqueVendorMappingTypeEdge = {
  __typename?: 'DetectionTechniqueVendorMappingTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DetectionTechniqueVendorMappingType>;
};

/** A suggested mapping between a Detection and a Threat Group. */
export type DetectionThreatGroupSuggestionType = Node & {
  __typename?: 'DetectionThreatGroupSuggestionType';
  /** The detection that the automapper is suggesting should be mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. After creation this value should only be changed by the user. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A score indicating the certainty of the suggestion. */
  score: Scalars['Int']['output'];
  /** A threat group the automapper suggest should be mapped to the detection. */
  threatGroup: ThreatGroupType;
};

/** User mapping between a Detection and a Threat Group. */
export type DetectionThreatGroupUserMappingType = Node & {
  __typename?: 'DetectionThreatGroupUserMappingType';
  /** The detection that the user mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a threat group created by a user. */
  threatGroup: ThreatGroupType;
};

/** Vendor mapping between a Detection and a Threat Group. */
export type DetectionThreatGroupVendorMappingType = Node & {
  __typename?: 'DetectionThreatGroupVendorMappingType';
  /** The detection that the integration vendor mapped to the other object. */
  detection: DetectionType;
  detectionName?: Maybe<Scalars['String']['output']>;
  /** If this mapping should be applied to the detection. */
  enabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents when this mapping was last modified. */
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  mitreId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A mapping that a detection detects a threat group created by a vendor. */
  threatGroup: ThreatGroupType;
};

/** Logic that generates alerts. */
export type DetectionType = Node &
  StixObject & {
    __typename?: 'DetectionType';
    /** The author of this detection. */
    author?: Maybe<Scalars['String']['output']>;
    automapSoftwareMappings?: Maybe<
      Array<Maybe<DetectionSoftwareSuggestionType>>
    >;
    automapTechniqueMappings?: Maybe<
      Array<Maybe<DetectionTechniqueSuggestionType>>
    >;
    automapThreatGroupMappings?: Maybe<
      Array<Maybe<DetectionThreatGroupSuggestionType>>
    >;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    definitionSource?: Maybe<Scalars['String']['output']>;
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The type of detection, normally matches the integration type. */
    detectionType?: Maybe<DetectionTypeType>;
    id: Scalars['ID']['output'];
    /** The number of indicators this detection created. */
    indicatorsCount: Scalars['Int']['output'];
    /** The integration this detection is from. */
    integration?: Maybe<IntegrationType>;
    integrationName?: Maybe<Scalars['String']['output']>;
    /** Any license associated with this detection. */
    license?: Maybe<Scalars['String']['output']>;
    /** The logic for this detection. */
    logic?: Maybe<Scalars['JSONString']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    notional?: Maybe<Scalars['Boolean']['output']>;
    /** If the detection prevents the activity instead of just alerting. */
    prevention: Scalars['Boolean']['output'];
    /** A unique ID representing this detection, most commonly an internal unique ID from the integration. */
    referenceId?: Maybe<Scalars['String']['output']>;
    /** The default severity of an alert generated by this detection. */
    severity: DetectionsDetectionSeverityChoices;
    /** The software this detection helps detect. */
    software: SoftwareTypeConnection;
    softwareCount?: Maybe<Scalars['Int']['output']>;
    /** The current state of the detection. */
    state: DetectionsDetectionStateChoices;
    /** The time the detection last changed state. */
    stateChangeTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    techniqueMitreIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The adversarial techniques this detection helps detect. */
    techniques: TechniqueTypeConnection;
    techniquesCount?: Maybe<Scalars['Int']['output']>;
    /** The threat groups this detection helps detect. */
    threatGroups: ThreatGroupTypeConnection;
    threatGroupsCount?: Maybe<Scalars['Int']['output']>;
    /** The name of the detection type. */
    type?: Maybe<Scalars['String']['output']>;
    userSoftwareMappings?: Maybe<
      Array<Maybe<DetectionSoftwareUserMappingType>>
    >;
    userTechniqueMappings?: Maybe<
      Array<Maybe<DetectionTechniqueUserMappingType>>
    >;
    userThreatGroupMappings?: Maybe<
      Array<Maybe<DetectionThreatGroupUserMappingType>>
    >;
    vendorSoftwareMappings?: Maybe<
      Array<Maybe<DetectionSoftwareVendorMappingType>>
    >;
    vendorTechniqueMappings?: Maybe<
      Array<Maybe<DetectionTechniqueVendorMappingType>>
    >;
    vendorThreatGroupMappings?: Maybe<
      Array<Maybe<DetectionThreatGroupVendorMappingType>>
    >;
    /** If the detection has been verified. */
    verified: Scalars['Boolean']['output'];
  };

/** Logic that generates alerts. */
export type DetectionTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Logic that generates alerts. */
export type DetectionTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Logic that generates alerts. */
export type DetectionTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DetectionTypeConnection = {
  __typename?: 'DetectionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DetectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DetectionType` and its cursor. */
export type DetectionTypeEdge = {
  __typename?: 'DetectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DetectionType>;
};

/** A classification of a detection. */
export type DetectionTypeType = Node & {
  __typename?: 'DetectionTypeType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The integration type, generally a product, that this detection is compatible with. */
  integrationType?: Maybe<IntegrationTypeType>;
  /** Name of the detection type. */
  name: Scalars['String']['output'];
  /** The parameters needed to create a detection of this type. */
  parameters?: Maybe<Scalars['GenericScalar']['output']>;
  /** If a detection of this type can be created and managed within Interpres. */
  readOnly: Scalars['Boolean']['output'];
};

export type DetectionTypeTypeConnection = {
  __typename?: 'DetectionTypeTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DetectionTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DetectionTypeType` and its cursor. */
export type DetectionTypeTypeEdge = {
  __typename?: 'DetectionTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DetectionTypeType>;
};

/** An enumeration. */
export enum DetectionsDetectionSeverityChoices {
  /** Critical */
  Critical = 'CRITICAL',
  /** High */
  High = 'HIGH',
  /** Info */
  Info = 'INFO',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

/** An enumeration. */
export enum DetectionsDetectionStateChoices {
  /** Archived */
  Archived = 'ARCHIVED',
  /** Disabled */
  Disabled = 'DISABLED',
  /** Enabled */
  Enabled = 'ENABLED',
  /** In Progress */
  InProgress = 'IN_PROGRESS'
}

/** Disable a detection mapping. */
export type DisableDetectionMappingMutation = {
  __typename?: 'DisableDetectionMappingMutation';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** A category defining a large range of technologies an adversary may target. */
export type DomainType = Node & {
  __typename?: 'DomainType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The name of the domain. Common examples are 'enterprise', 'mobile', and 'ICS'. */
  name: Scalars['String']['output'];
};

export type DomainTypeConnection = {
  __typename?: 'DomainTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DomainTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DomainType` and its cursor. */
export type DomainTypeEdge = {
  __typename?: 'DomainTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DomainType>;
};

/** Enable a detection mapping. */
export type EnableDetectionMappingMutation = {
  __typename?: 'EnableDetectionMappingMutation';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type EnableIntegration = {
  integrationId: Scalars['String']['input'];
};

export type ExecuteIntegrationAction = {
  actionIdentifier: Scalars['String']['input'];
  environment?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type ExposureScoreEntry = {
  __typename?: 'ExposureScoreEntry';
  /** The time of these scores changed. */
  datetime?: Maybe<Scalars['String']['output']>;
  /** The amount the defense posture score changed. */
  defensePostureDelta?: Maybe<Scalars['Int']['output']>;
  /** The new defense posture score at the time of the change. */
  defensePostureScore?: Maybe<Scalars['Int']['output']>;
  /** The amount the score changed. */
  delta?: Maybe<Scalars['Int']['output']>;
  /** The amount the score changed. */
  fleetExposureDelta?: Maybe<Scalars['Int']['output']>;
  /** The new score at the time of the change. */
  fleetExposureScore?: Maybe<Scalars['Int']['output']>;
  /** The new score at the time of the change. */
  score?: Maybe<Scalars['Int']['output']>;
  /** The amount the score changed. */
  threatExposureDelta?: Maybe<Scalars['Int']['output']>;
  /** The new threat exposure score at the time of the change. */
  threatExposureScore?: Maybe<Scalars['Int']['output']>;
  topCampaignsSummary?: Maybe<Array<Maybe<TopCampaignsSummary>>>;
  topPrioritizedPlatformsSummary?: Maybe<
    Array<Maybe<TopPrioritizedPlatformsSummary>>
  >;
  topPrioritizedSoftwareSummary?: Maybe<
    Array<Maybe<TopPrioritizedSoftwareSummary>>
  >;
  topPrioritizedTechniquesSummary?: Maybe<
    Array<Maybe<TopPrioritizedTechniquesSummary>>
  >;
  topPrioritizedThreatGroupsSummary?: Maybe<
    Array<Maybe<TopPrioritizedThreatGroupsSummary>>
  >;
};

export type ExposureScoreHistory = {
  __typename?: 'ExposureScoreHistory';
  /** Number of entries in the exposure score history. */
  count?: Maybe<Scalars['Int']['output']>;
  /** A list of exposure score changes. */
  entries?: Maybe<Array<Maybe<ExposureScoreEntry>>>;
};

/** Generates a new report for the report type in context */
export type GenerateReport = {
  __typename?: 'GenerateReport';
  report?: Maybe<GenerateReportResponse>;
};

export type GenerateReportInput = {
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  reportName: Scalars['String']['input'];
  requestedSections?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GenerateReportResponse = {
  __typename?: 'GenerateReportResponse';
  code: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  sig: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Group = {
  __typename?: 'Group';
  groupId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roles: Array<Maybe<Role>>;
  users: Array<Maybe<User>>;
};

export type GroupStatus = {
  __typename?: 'GroupStatus';
  group?: Maybe<Group>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Interpres GraphQL API Health Check. */
export type Health = {
  __typename?: 'Health';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** A classification of identifiers based on input parameters. */
export type IdentifierTypeType = Node & {
  __typename?: 'IdentifierTypeType';
  /** A short description of this type of identifier. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The type of integration this identifier applies to. */
  integrationType: IntegrationTypeType;
  /** The name of this identifier type. */
  name: Scalars['String']['output'];
  /** The parameters required to create this type of TelemetryIdentifier. */
  parameters?: Maybe<Scalars['GenericScalar']['output']>;
  /** If custom identifiers of this type are allowed. */
  static: Scalars['Boolean']['output'];
};

export type IdentifierTypeTypeConnection = {
  __typename?: 'IdentifierTypeTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IdentifierTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IdentifierTypeType` and its cursor. */
export type IdentifierTypeTypeEdge = {
  __typename?: 'IdentifierTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IdentifierTypeType>;
};

/** An enumeration. */
export enum IdentitiesIdentityLabelOriginChoices {
  /** integration */
  Integration = 'INTEGRATION',
  /** system */
  System = 'SYSTEM',
  /** user */
  User = 'USER'
}

export type IdentityConnection = {
  __typename?: 'IdentityConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IdentityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `Identity` and its cursor. */
export type IdentityEdge = {
  __typename?: 'IdentityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IdentityType>;
};

/** A label describing a feature of this identity. */
export type IdentityLabelType = Node & {
  __typename?: 'IdentityLabelType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  origin: IdentitiesIdentityLabelOriginChoices;
};

export type IdentityLabelTypeConnection = {
  __typename?: 'IdentityLabelTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IdentityLabelTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IdentityLabelType` and its cursor. */
export type IdentityLabelTypeEdge = {
  __typename?: 'IdentityLabelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IdentityLabelType>;
};

/** An object representing an identity. */
export type IdentityType = Node &
  StixObject & {
    __typename?: 'IdentityType';
    assets?: Maybe<Array<Maybe<AssetType>>>;
    departments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    emailAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The first time the identity was seen by any integration. */
    firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    id: Scalars['ID']['output'];
    integrationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    labelNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    labels: IdentityLabelTypeConnection;
    languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The last time the identity was seen by any integration. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    phoneNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    relatedAssetNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
  };

/** An object representing an identity. */
export type IdentityTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IdentityTypeConnection = {
  __typename?: 'IdentityTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IdentityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IdentityType` and its cursor. */
export type IdentityTypeEdge = {
  __typename?: 'IdentityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IdentityType>;
};

export type Industry = {
  __typename?: 'Industry';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** A mapping from Interpres's field name to the integrations field name. */
export type InformationModelAliasType = Node & {
  __typename?: 'InformationModelAliasType';
  fieldName: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  newFieldName: Scalars['String']['output'];
};

export type InformationModelInput = {
  /** Name of the information model. */
  name: Scalars['String']['input'];
};

/** A lookup table that maps Interpres's field names to an integrations field names. */
export type InformationModelType = Node & {
  __typename?: 'InformationModelType';
  aliases?: Maybe<Array<Maybe<InformationModelAliasType>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InformationModelTypeConnection = {
  __typename?: 'InformationModelTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformationModelTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `InformationModelType` and its cursor. */
export type InformationModelTypeEdge = {
  __typename?: 'InformationModelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InformationModelType>;
};

export type Integration = {
  __typename?: 'Integration';
  /** The app id */
  appId: Scalars['String']['output'];
  /** The config */
  config: Scalars['IntegrationConfig']['output'];
  /** Integration description */
  description?: Maybe<Scalars['String']['output']>;
  /** The integration id */
  integrationId: Scalars['String']['output'];
  /** The Integrations Schedule */
  integrationSchedule?: Maybe<Schedule>;
  /** The name provided for the integration. */
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum IntegrationActions {
  GetAlerts = 'GET_ALERTS',
  GetAssets = 'GET_ASSETS',
  GetAvailableTelemetry = 'GET_AVAILABLE_TELEMETRY',
  GetCampaigns = 'GET_CAMPAIGNS',
  GetDetections = 'GET_DETECTIONS',
  GetIdentities = 'GET_IDENTITIES',
  GetPolicies = 'GET_POLICIES',
  GetRecommendedActions = 'GET_RECOMMENDED_ACTIONS',
  GetStixObjects = 'GET_STIX_OBJECTS',
  GetVulnerabilities = 'GET_VULNERABILITIES',
  TestConnectivity = 'TEST_CONNECTIVITY',
  UpdateThreatModel = 'UPDATE_THREAT_MODEL'
}

/** An object representing an integration asset. */
export type IntegrationAssetType = Node &
  StixObject & {
    __typename?: 'IntegrationAssetType';
    applications?: Maybe<AssetApplicationConnection>;
    /** The number of detections, preventions, and controls an organization has protecting this asset. */
    content: Scalars['Int']['output'];
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** If the asset has been flagged as critical to an organization. */
    critical: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The hostname of the asset. */
    hostname?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    identities?: Maybe<IdentityConnection>;
    integration?: Maybe<IntegrationType>;
    /** The asset to whom this IP belongs. */
    ips: AssetIpTypeConnection;
    labels: AssetLabelTypeConnection;
    /** The last time the asset was seen by the integration. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The Operating System running on the asset. */
    operatingSystem?: Maybe<Scalars['String']['output']>;
    platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** This asset's platforms. */
    platforms: PlatformTypeConnection;
    /** Policies attached to this asset. */
    policies: PolicyTypeConnection;
    policyNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** A value representing how much an organization should prioritize this asset based on their threat profile. This value does not take existing protections into consideration. */
    priority: Scalars['Int']['output'];
    /** The internal unique ID of this asset in the integration. */
    referenceId: Scalars['String']['output'];
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    uniqueApplicationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    uniqueIpAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    uniqueMacAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    vulnerabilities?: Maybe<PrioritizedVulnerabilityConnection>;
    vulnerabilityCveIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** An object representing an integration asset. */
export type IntegrationAssetTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypeIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypeIpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypePoliciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing an integration asset. */
export type IntegrationAssetTypeVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationAssetTypeConnection = {
  __typename?: 'IntegrationAssetTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationAssetTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationAssetType` and its cursor. */
export type IntegrationAssetTypeEdge = {
  __typename?: 'IntegrationAssetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationAssetType>;
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `Integration` and its cursor. */
export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationType>;
};

/** An object representing an integration identity. */
export type IntegrationIdentityType = Node &
  StixObject & {
    __typename?: 'IntegrationIdentityType';
    assets?: Maybe<Array<Maybe<AssetType>>>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** The department or organization this identity belongs to. */
    department?: Maybe<Scalars['String']['output']>;
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    emailAddresses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The first time the identity was seen by the integration. */
    firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    id: Scalars['ID']['output'];
    identityNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The integration that the identity data was pulled from. */
    integration?: Maybe<IntegrationType>;
    /** The identity's job title. */
    jobTitle?: Maybe<Scalars['String']['output']>;
    labelNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    labels: IdentityLabelTypeConnection;
    /** The identity's language. */
    language?: Maybe<Scalars['String']['output']>;
    /** The last time the identity was seen by the integration. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    phoneNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The internal unique ID of this identity in the integration. */
    referenceId: Scalars['String']['output'];
    roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The state of this identity, e.g. Active, Disabled, Locked. */
    status?: Maybe<Scalars['String']['output']>;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
  };

/** An object representing an integration identity. */
export type IntegrationIdentityTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationIdentityTypeConnection = {
  __typename?: 'IntegrationIdentityTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationIdentityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationIdentityType` and its cursor. */
export type IntegrationIdentityTypeEdge = {
  __typename?: 'IntegrationIdentityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationIdentityType>;
};

/** An object that describes the integration mode. */
export type IntegrationModeType = Node & {
  __typename?: 'IntegrationModeType';
  /** Signifies whether the integration mode is collected. */
  collected: Scalars['Boolean']['output'];
  /** A short description describing capabilities of this mode. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The name of the integration app mode. */
  name: Scalars['String']['output'];
  /** Signifies whether the integration mode is notional. */
  notional: Scalars['Boolean']['output'];
};

export type IntegrationModeTypeConnection = {
  __typename?: 'IntegrationModeTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationModeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationModeType` and its cursor. */
export type IntegrationModeTypeEdge = {
  __typename?: 'IntegrationModeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationModeType>;
};

export type IntegrationNamesAndOrFilterInput = {
  integrationNames: Scalars['String']['input'];
  useOr?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationProductTypeEntry = {
  __typename?: 'IntegrationProductTypeEntry';
  /** The total number of alerts in the last week from this product type. */
  alertsCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of alerts in the last week from this product type. A number between 0-100. */
  alertsPercentage?: Maybe<Scalars['Int']['output']>;
  /** The number of Integrations of this product type. */
  integrationsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of integrations of this product type with a low coverage score. */
  lowCoverageIntegrationsCount?: Maybe<Scalars['Int']['output']>;
  /** The name of the product type. */
  name?: Maybe<Scalars['String']['output']>;
};

export type IntegrationProductTypeSummary = {
  __typename?: 'IntegrationProductTypeSummary';
  /** The total number of alerts in the last week. */
  alertsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of Integrations. */
  integrationsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of integrations with a low coverage score. */
  lowCoverageIntegrationsCount?: Maybe<Scalars['Int']['output']>;
  /** A list of summary data per product type. */
  productTypes?: Maybe<Array<Maybe<IntegrationProductTypeEntry>>>;
};

export type IntegrationRecommendedActionInputType = {
  /** The status of the recommended action. */
  status: IntegrationRecommendedActionStatus;
};

/** An enumeration. */
export enum IntegrationRecommendedActionStatus {
  Completed = 'COMPLETED',
  Ignored = 'IGNORED',
  InProgress = 'IN_PROGRESS',
  NotImplemented = 'NOT_IMPLEMENTED',
  NotStarted = 'NOT_STARTED',
  ResolvedThroughAlternativeMitigation = 'RESOLVED_THROUGH_ALTERNATIVE_MITIGATION',
  ResolvedThroughThirdParty = 'RESOLVED_THROUGH_THIRD_PARTY',
  RiskAccepted = 'RISK_ACCEPTED',
  ToAddress = 'TO_ADDRESS'
}

/** Actionable integration specific recommended actions an organization can perform to improve their security posture. */
export type IntegrationRecommendedActionType = Node & {
  __typename?: 'IntegrationRecommendedActionType';
  /** A basic categorization for sorting and filtering this action. */
  category: IntegrationsIntegrationRecommendedActionCategoryChoices;
  /** Represents the time at which this policy object was originally created. */
  createdTimestamp: Scalars['DateTime']['output'];
  /** An optional description about this recommended action. */
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The integration from which this policy originated. */
  integration: IntegrationType;
  integrationName?: Maybe<Scalars['String']['output']>;
  /** Represents the last time this policy has been returned. */
  lastSeenTimestamp: Scalars['DateTime']['output'];
  /** Represents the time at which this object was last modified. */
  modifiedTimestamp: Scalars['DateTime']['output'];
  /** The human readable name for this action. */
  name: Scalars['String']['output'];
  platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The platforms the policies action will affect, if blank it will use the policies platforms. */
  platforms: PlatformTypeConnection;
  /** The policy in which this action is requesting to change. */
  policy?: Maybe<PolicyType>;
  policyAssetsCount?: Maybe<Scalars['Int']['output']>;
  policyIntegrationAssetsCount?: Maybe<Scalars['Int']['output']>;
  policyName?: Maybe<Scalars['String']['output']>;
  /** An unique ID for this action, if applicable uses rules reference_id. Defaults to name. */
  referenceId: Scalars['String']['output'];
  /** The rule that generated this policy. */
  rule?: Maybe<PolicyRuleType>;
  /** Current completion status of this action. */
  status: IntegrationsIntegrationRecommendedActionStatusChoices;
  /** The adversarial techniques this policy change would help detect or prevent. */
  techniques: TechniqueTypeConnection;
  techniquesCount?: Maybe<Scalars['Int']['output']>;
  /** The telemetry subcategories this policy change would affect. */
  telemetrySubcategories: TelemetrySubcategoryTypeConnection;
  telemetrySubcategoriesCount?: Maybe<Scalars['Int']['output']>;
};

/** Actionable integration specific recommended actions an organization can perform to improve their security posture. */
export type IntegrationRecommendedActionTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Actionable integration specific recommended actions an organization can perform to improve their security posture. */
export type IntegrationRecommendedActionTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Actionable integration specific recommended actions an organization can perform to improve their security posture. */
export type IntegrationRecommendedActionTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationRecommendedActionTypeConnection = {
  __typename?: 'IntegrationRecommendedActionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationRecommendedActionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationRecommendedActionType` and its cursor. */
export type IntegrationRecommendedActionTypeEdge = {
  __typename?: 'IntegrationRecommendedActionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationRecommendedActionType>;
};

export type IntegrationStatus = {
  __typename?: 'IntegrationStatus';
  integration: Integration;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type IntegrationTagInput = {
  /** A brief description of the tag. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the tag. */
  name: Scalars['String']['input'];
};

/** A tagging mechanism so users can tag integrations. */
export type IntegrationTagType = Node & {
  __typename?: 'IntegrationTagType';
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  integrations: IntegrationTypeConnection;
  name: Scalars['String']['output'];
};

/** A tagging mechanism so users can tag integrations. */
export type IntegrationTagTypeIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationTagTypeConnection = {
  __typename?: 'IntegrationTagTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationTagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationTagType` and its cursor. */
export type IntegrationTagTypeEdge = {
  __typename?: 'IntegrationTagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationTagType>;
};

/** An instance of an integration. */
export type IntegrationType = Node & {
  __typename?: 'IntegrationType';
  /** The percentage of alerts in the last week created by this Integration. This value is an integer from 0-100. */
  alertsPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Alerts Percentage score. */
  alertsPercentageDescription: Scalars['String']['output'];
  /** The app id for this type of integration. */
  appId?: Maybe<Scalars['String']['output']>;
  assetsCount?: Maybe<Scalars['Int']['output']>;
  availableActions?: Maybe<Array<Maybe<IntegrationActions>>>;
  availableTelemetryIdentifiersCount?: Maybe<Scalars['Int']['output']>;
  /** The arguments matching the integration type parameters. */
  config?: Maybe<Scalars['GenericScalar']['output']>;
  connectivity: Scalars['Boolean']['output'];
  connectivityMessage: Scalars['String']['output'];
  /** The percentage of available controls for this Integration currently in place. This value is an integer from 0-100. */
  controlsPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Controls Percentage score. */
  controlsPercentageDescription: Scalars['String']['output'];
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** The integration this detection is from. */
  detections: DetectionTypeConnection;
  detectionsCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available detections for this Integration currently enabled. This value is an integer from 0-100. */
  detectionsPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Detection Percentage score. */
  detectionsPercentageDescription: Scalars['String']['output'];
  disabledDetectionsCount?: Maybe<Scalars['Int']['output']>;
  enabled: Scalars['Boolean']['output'];
  enabledDetectionsCount?: Maybe<Scalars['Int']['output']>;
  /** Environment to run the integration. */
  environment?: Maybe<Scalars['String']['output']>;
  frequency: Scalars['Int']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  informationModel?: Maybe<InformationModelType>;
  integrationIdentitiesCount?: Maybe<Scalars['Int']['output']>;
  integrationType?: Maybe<IntegrationTypeType>;
  lastSuccessfulConnectivityTest?: Maybe<Scalars['DateTime']['output']>;
  /** Existing manual uploads. */
  manualUploads?: Maybe<Array<Maybe<ManualDataType>>>;
  /** Integration mode, signfies whether the integration uses notional data vs collected data or both */
  modes: IntegrationModeTypeConnection;
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  prioritizedCampaignsCount?: Maybe<Scalars['Int']['output']>;
  /** The category or type of product of this integration. */
  productType?: Maybe<Scalars['String']['output']>;
  /** The list of product types this integration falls under. */
  productTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recommendedActionsCount?: Maybe<Scalars['Int']['output']>;
  tags: IntegrationTagTypeConnection;
  /** The integration that this identifier is run on. */
  telemetryIdentifiers: TelemetryIdentifierTypeConnection;
  telemetryIdentifiersCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available telemetry for this Integration currently available. This value is an integer from 0-100. */
  telemetryPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Telemetry Percentage score. */
  telemetryPercentageDescription: Scalars['String']['output'];
  /** The integration type name. */
  type?: Maybe<Scalars['String']['output']>;
  unavailableTelemetryIdentifiersCount?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['String']['output'];
  vulnerabilitiesCount?: Maybe<Scalars['Int']['output']>;
};

/** An instance of an integration. */
export type IntegrationTypeDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An instance of an integration. */
export type IntegrationTypeModesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An instance of an integration. */
export type IntegrationTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An instance of an integration. */
export type IntegrationTypeTelemetryIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationTypeConnection = {
  __typename?: 'IntegrationTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationType` and its cursor. */
export type IntegrationTypeEdge = {
  __typename?: 'IntegrationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationType>;
};

/** An object that describes a type of integration and the required fields. */
export type IntegrationTypeType = Node & {
  __typename?: 'IntegrationTypeType';
  actions?: Maybe<Scalars['GenericScalar']['output']>;
  /** The ID for the App that runs this configuration. */
  appId: Scalars['String']['output'];
  /** A short description about the product. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The common name for the product. */
  name: Scalars['String']['output'];
  parameters?: Maybe<Scalars['GenericScalar']['output']>;
  /** The type or category of the product. */
  productType: Scalars['String']['output'];
  productTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The name of the vendor of the product this integration is used for. */
  productVendor: Scalars['String']['output'];
};

export type IntegrationTypeTypeConnection = {
  __typename?: 'IntegrationTypeTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationTypeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntegrationTypeType` and its cursor. */
export type IntegrationTypeTypeEdge = {
  __typename?: 'IntegrationTypeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationTypeType>;
};

/** An enumeration. */
export enum IntegrationsIntegrationRecommendedActionCategoryChoices {
  /** Detection */
  Detection = 'DETECTION',
  /** General */
  General = 'GENERAL',
  /** Prevention */
  Prevention = 'PREVENTION',
  /** Telemetry */
  Telemetry = 'TELEMETRY'
}

/** An enumeration. */
export enum IntegrationsIntegrationRecommendedActionStatusChoices {
  /** Completed */
  Completed = 'COMPLETED',
  /** Ignored */
  Ignored = 'IGNORED',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Not Implemented */
  NotImplemented = 'NOT_IMPLEMENTED',
  /** Not Started */
  NotStarted = 'NOT_STARTED',
  /** Resolved through alternative mitigation */
  ResolvedThroughAlternativeMitigation = 'RESOLVED_THROUGH_ALTERNATIVE_MITIGATION',
  /** resolved through third party */
  ResolvedThroughThirdParty = 'RESOLVED_THROUGH_THIRD_PARTY',
  /** Risk Accepted */
  RiskAccepted = 'RISK_ACCEPTED',
  /** To address */
  ToAddress = 'TO_ADDRESS'
}

/** An enumeration. */
export enum IntegrationtypesPolicyRuleSeverityChoices {
  /** Critical */
  Critical = 'CRITICAL',
  /** High */
  High = 'HIGH',
  /** Info */
  Info = 'INFO',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

export type InviteUser = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type LabelFilterInput = {
  labels: Scalars['String']['input'];
  useOr?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListChildrenResponse = {
  __typename?: 'ListChildrenResponse';
  children: Array<Maybe<ChildTenant>>;
};

export type ListGroupResponse = {
  __typename?: 'ListGroupResponse';
  groups: Array<Maybe<Group>>;
  totalCount: Scalars['Int']['output'];
};

export type ListRemoteCollectorResponse = {
  __typename?: 'ListRemoteCollectorResponse';
  collectors: Array<Maybe<RemoteCollectorEntity>>;
  totalCount: Scalars['Int']['output'];
};

export type ListRoleResponse = {
  __typename?: 'ListRoleResponse';
  roles: Array<Maybe<Role>>;
  totalCount: Scalars['Int']['output'];
};

export type ListUserResponse = {
  __typename?: 'ListUserResponse';
  totalCount: Scalars['Int']['output'];
  users: Array<Maybe<User>>;
};

/** An enumeration. */
export enum ManualDataType {
  Assets = 'ASSETS',
  Campaigns = 'CAMPAIGNS',
  Detections = 'DETECTIONS',
  Vulnerabilities = 'VULNERABILITIES'
}

/** Interpres GraphQL mutations. */
export type Mutation = {
  __typename?: 'Mutation';
  addLabelToAsset?: Maybe<AddLabelToAsset>;
  addLabelToIdentity?: Maybe<AddLabelToIdentity>;
  /** IntegrationTag an integration. */
  addTagToIntegration?: Maybe<AddTagToIntegration>;
  /** Add multiple tags to an integration, any tag not found will be created. */
  addTagsToIntegration?: Maybe<AddTagsToIntegration>;
  addUserToGroup: MutationStatus;
  /** Clears manually uploaded data. */
  clearManualData?: Maybe<ClearManualData>;
  /** Creates a copy of an information model with a new name. */
  copyInformationModel?: Maybe<CopyInformationModel>;
  createAppIntegration: IntegrationStatus;
  /** Creates a ChatBotHistory */
  createChatBotHistory?: Maybe<CreateChatBotHistory>;
  createChildTenant: MutationStatus;
  createCollectorEntity: CreateCollectorEntityResponse;
  /** Add relationship to detection. Note, relationships can only be disabled not deleted after creation. */
  createDetectionMapping?: Maybe<CreateDetectionMapping>;
  createGroup: GroupStatus;
  /** Creates an information model. */
  createInformationModel?: Maybe<CreateInformationModel>;
  /** Creates an information model alias. */
  createInformationModelAlias?: Maybe<CreateInformationModelAlias>;
  /** Creates an integration. */
  createIntegration?: Maybe<CreateIntegration>;
  /** Creates an integration tag. */
  createIntegrationTag?: Maybe<CreateIntegrationTag>;
  createSchedule: MutationStatus;
  /** Creates a threat profile. */
  createThreatProfile?: Maybe<CreateThreatProfile>;
  /** Creates a service ticket in ServiceNow */
  createTicket?: Maybe<CreateTicket>;
  createToken?: Maybe<CreateToken>;
  createUser: UserStatus;
  deactivateUser: MutationStatus;
  deleteAppIntegration: MutationStatus;
  /** Deletes a given ChatBotHistory */
  deleteChatBotHistory?: Maybe<DeleteChatBotHistory>;
  deleteCollectorEntity: MutationStatus;
  deleteGroup: MutationStatus;
  /** Deletes an information model. */
  deleteInformationModel?: Maybe<DeleteInformationModel>;
  /** Deletes an information model alias. */
  deleteInformationModelAlias?: Maybe<DeleteInformationModelAlias>;
  /** Deletes an integration. */
  deleteIntegration?: Maybe<DeleteIntegration>;
  /** Deletes a tag. */
  deleteIntegrationTag?: Maybe<DeleteIntegrationTag>;
  /** Deletes a threat profile. */
  deleteThreatProfile?: Maybe<DeleteThreatProfile>;
  deleteToken?: Maybe<DeleteToken>;
  deleteUser: MutationStatus;
  /** Disable a detection mapping. */
  disableDetectionMapping?: Maybe<DisableDetectionMappingMutation>;
  /** Enable a detection mapping. */
  enableDetectionMapping?: Maybe<EnableDetectionMappingMutation>;
  executeIntegrationAction: MutationStatus;
  /** Generates a new report for the report type in context */
  generateReport?: Maybe<GenerateReport>;
  /** Interpres GraphQL API Health Check. */
  health?: Maybe<Health>;
  inviteUser: UserStatus;
  reactivateUser: MutationStatus;
  removeLabelFromAsset?: Maybe<RemoveLabelFromAsset>;
  removeLabelFromIdentity?: Maybe<RemoveLabelFromIdentity>;
  /** Remove a tag from an integration. */
  removeTagFromIntegration?: Maybe<RemoveTagFromIntegration>;
  /** Remove a list of tags from an integration. */
  removeTagsFromIntegration?: Maybe<RemoveTagsFromIntegration>;
  removeUserFromGroup: MutationStatus;
  /** Rolls the admin logs. */
  rollLogs?: Maybe<RollLogs>;
  /** Schedules the specified action to run on the integration. **NOT FUNCTIONAL** */
  runActionOnIntegration?: Maybe<RunActionOnIntegration>;
  updateChildTenant: MutationStatus;
  updateCurrentUser: UserStatus;
  updateGroup: GroupStatus;
  /** Updates an information model. */
  updateInformationModel?: Maybe<UpdateInformationModel>;
  /** Updates an information model alias. */
  updateInformationModelAlias?: Maybe<UpdateInformationModelAlias>;
  /** Updates an integration. */
  updateIntegration?: Maybe<UpdateIntegration>;
  /** Updates a recommended action. */
  updateIntegrationRecommendedAction?: Maybe<UpdateIntegrationRecommendedAction>;
  /** Updates an integration tag. */
  updateIntegrationTag?: Maybe<UpdateIntegrationTag>;
  /** Updates a recommended action. */
  updateRecommendedAction?: Maybe<UpdateRecommendedAction>;
  /** Updates a threat profile. */
  updateThreatProfile?: Maybe<UpdateThreatProfile>;
  updateUser: UserStatus;
};

/** Interpres GraphQL mutations. */
export type MutationAddLabelToAssetArgs = {
  input: AddLabelToAssetInput;
};

/** Interpres GraphQL mutations. */
export type MutationAddLabelToIdentityArgs = {
  input: AddLabelToIdentityInput;
};

/** Interpres GraphQL mutations. */
export type MutationAddTagToIntegrationArgs = {
  input: AddTagToIntegrationInput;
};

/** Interpres GraphQL mutations. */
export type MutationAddTagsToIntegrationArgs = {
  input: AddTagsToIntegrationInput;
};

/** Interpres GraphQL mutations. */
export type MutationAddUserToGroupArgs = {
  input?: InputMaybe<AddGroupMembership>;
};

/** Interpres GraphQL mutations. */
export type MutationClearManualDataArgs = {
  input: ClearManualDataInput;
};

/** Interpres GraphQL mutations. */
export type MutationCopyInformationModelArgs = {
  input: CopyInformationModelInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateAppIntegrationArgs = {
  input: CreateIntegrationConfig;
};

/** Interpres GraphQL mutations. */
export type MutationCreateChatBotHistoryArgs = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationCreateChildTenantArgs = {
  input?: InputMaybe<CreateChildTenant>;
};

/** Interpres GraphQL mutations. */
export type MutationCreateCollectorEntityArgs = {
  input?: InputMaybe<CreateCollectorEntity>;
};

/** Interpres GraphQL mutations. */
export type MutationCreateDetectionMappingArgs = {
  input: CreateDetectionMappingInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateGroupArgs = {
  input?: InputMaybe<CreateGroup>;
};

/** Interpres GraphQL mutations. */
export type MutationCreateInformationModelArgs = {
  input: InformationModelInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateInformationModelAliasArgs = {
  input: CreateInformationModelAliasInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInputType;
};

/** Interpres GraphQL mutations. */
export type MutationCreateIntegrationTagArgs = {
  input: IntegrationTagInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateScheduleArgs = {
  input?: InputMaybe<Array<CreateSchedule>>;
};

/** Interpres GraphQL mutations. */
export type MutationCreateThreatProfileArgs = {
  input: ThreatProfileInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};

/** Interpres GraphQL mutations. */
export type MutationCreateUserArgs = {
  input: CreateUser;
};

/** Interpres GraphQL mutations. */
export type MutationDeactivateUserArgs = {
  input: DeactivateUser;
};

/** Interpres GraphQL mutations. */
export type MutationDeleteAppIntegrationArgs = {
  input: DeleteIntegration;
};

/** Interpres GraphQL mutations. */
export type MutationDeleteChatBotHistoryArgs = {
  globalId: Scalars['String']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteCollectorEntityArgs = {
  input?: InputMaybe<DeleteWorkerEntity>;
};

/** Interpres GraphQL mutations. */
export type MutationDeleteGroupArgs = {
  input?: InputMaybe<DeleteGroup>;
};

/** Interpres GraphQL mutations. */
export type MutationDeleteInformationModelArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteInformationModelAliasArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteIntegrationTagArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteThreatProfileArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationDeleteUserArgs = {
  input: DeleteUser;
};

/** Interpres GraphQL mutations. */
export type MutationDisableDetectionMappingArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationEnableDetectionMappingArgs = {
  id: Scalars['ID']['input'];
};

/** Interpres GraphQL mutations. */
export type MutationExecuteIntegrationActionArgs = {
  input: ExecuteIntegrationAction;
};

/** Interpres GraphQL mutations. */
export type MutationGenerateReportArgs = {
  input: GenerateReportInput;
};

/** Interpres GraphQL mutations. */
export type MutationInviteUserArgs = {
  input: InviteUser;
};

/** Interpres GraphQL mutations. */
export type MutationReactivateUserArgs = {
  input: ReactivateUser;
};

/** Interpres GraphQL mutations. */
export type MutationRemoveLabelFromAssetArgs = {
  input: RemoveLabelFromAssetInput;
};

/** Interpres GraphQL mutations. */
export type MutationRemoveLabelFromIdentityArgs = {
  input: RemoveLabelFromIdentityInput;
};

/** Interpres GraphQL mutations. */
export type MutationRemoveTagFromIntegrationArgs = {
  input: RemoveTagFromIntegrationInput;
};

/** Interpres GraphQL mutations. */
export type MutationRemoveTagsFromIntegrationArgs = {
  input: RemoveTagsFromIntegrationInput;
};

/** Interpres GraphQL mutations. */
export type MutationRemoveUserFromGroupArgs = {
  input?: InputMaybe<RemoveGroupMembership>;
};

/** Interpres GraphQL mutations. */
export type MutationRunActionOnIntegrationArgs = {
  input: RunActionOnIntegrationInput;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateChildTenantArgs = {
  input?: InputMaybe<UpdateChildTenant>;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUser;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateGroupArgs = {
  input?: InputMaybe<UpdateGroup>;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateInformationModelArgs = {
  id: Scalars['ID']['input'];
  input: InformationModelInput;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateInformationModelAliasArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInformationModelAliasInput;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateIntegrationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateIntegrationInputType;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateIntegrationRecommendedActionArgs = {
  id: Scalars['ID']['input'];
  input: IntegrationRecommendedActionInputType;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateIntegrationTagArgs = {
  id: Scalars['ID']['input'];
  input: IntegrationTagInput;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateRecommendedActionArgs = {
  id: Scalars['ID']['input'];
  input: IntegrationRecommendedActionInputType;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateThreatProfileArgs = {
  id: Scalars['ID']['input'];
  input: ThreatProfileInput;
};

/** Interpres GraphQL mutations. */
export type MutationUpdateUserArgs = {
  input: UpdateUser;
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  /**  The data type  */
  dataType: Scalars['String']['output'];
  /**  The list of potential Values  */
  default?: Maybe<Scalars['String']['output']>;
  /**  Description of the Field  */
  description: Scalars['String']['output'];
  /**  Parameter Identifier  */
  identifier: Scalars['String']['output'];
  /**  Human Readable Name  */
  name: Scalars['String']['output'];
  /**  Order to be rendered in the form  */
  order: Scalars['Int']['output'];
  /**  If the field is a required input  */
  required: Scalars['Boolean']['output'];
  /**  The list of potential Values  */
  valueList?: Maybe<Array<Scalars['String']['output']>>;
};

/** A categorization of where a technique can occur, commonly an operating system. */
export type PlatformType = Node & {
  __typename?: 'PlatformType';
  /** A short description describing this platform. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The name of the platform, commonly an operating system name. */
  name: Scalars['String']['output'];
};

export type PlatformTypeConnection = {
  __typename?: 'PlatformTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlatformTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PlatformType` and its cursor. */
export type PlatformTypeEdge = {
  __typename?: 'PlatformTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PlatformType>;
};

/** An enumeration. */
export enum PoliciesPolicyStatusChoices {
  /** Archived */
  Archived = 'ARCHIVED',
  /** Disabled */
  Disabled = 'DISABLED',
  /** Enabled */
  Enabled = 'ENABLED'
}

/** Logic run against a policy that looks for recommended actions. */
export type PolicyRuleType = Node & {
  __typename?: 'PolicyRuleType';
  /** If this rule creates an action based on the pattern matching or not matching. */
  actionOnMatch: Scalars['Boolean']['output'];
  /** If this policy rule has been deprecated and no longer should be run. */
  deprecated: Scalars['Boolean']['output'];
  /** Optional explanation of what this policy rule is looking for and the impact of the change. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The type of integration that has this policy rules applies. */
  integrationType?: Maybe<IntegrationTypeType>;
  /** Represents when this rule was last updated from S3. */
  modifiedTimestamp: Scalars['DateTime']['output'];
  /** Name of the policy rule. */
  name: Scalars['String']['output'];
  /** The platforms the policies action will affect, if blank it will use the policies platforms. */
  platforms: PlatformTypeConnection;
  /** The level of the impact this policy change would have on an organizations security posture. */
  severity: IntegrationtypesPolicyRuleSeverityChoices;
  /** The adversarial techniques this policy change would help detect or prevent. */
  techniques: TechniqueTypeConnection;
  /** The telemetry subcategories this policy change would affect. */
  telemetrySubcategories: TelemetrySubcategoryTypeConnection;
  /** The UUID for this policy. */
  uuid: Scalars['String']['output'];
};

/** Logic run against a policy that looks for recommended actions. */
export type PolicyRuleTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Logic run against a policy that looks for recommended actions. */
export type PolicyRuleTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Logic run against a policy that looks for recommended actions. */
export type PolicyRuleTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PolicyRuleTypeConnection = {
  __typename?: 'PolicyRuleTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyRuleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PolicyRuleType` and its cursor. */
export type PolicyRuleTypeEdge = {
  __typename?: 'PolicyRuleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PolicyRuleType>;
};

/** The configurations or policy from an integration. */
export type PolicyType = Node & {
  __typename?: 'PolicyType';
  /** Represents the time at which this policy object was originally created. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The policy in JSON form. */
  data: Scalars['JSONString']['output'];
  /** An optional description about the policy. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The integration from which this policy originated. */
  integration: IntegrationType;
  integrationAssetCount?: Maybe<Scalars['Int']['output']>;
  /** Represents the last time this policy has been returned. */
  lastSeenTimestamp: Scalars['DateTime']['output'];
  /** The human readable name for this policy. */
  name: Scalars['String']['output'];
  /** The platforms this policies applies to. */
  platforms: PlatformTypeConnection;
  /** An unique ID for this policy, if not provided name will be used instead. */
  referenceId: Scalars['String']['output'];
  /** The current state of this policy. */
  status: PoliciesPolicyStatusChoices;
};

/** The configurations or policy from an integration. */
export type PolicyTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PolicyTypeConnection = {
  __typename?: 'PolicyTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PolicyType` and its cursor. */
export type PolicyTypeEdge = {
  __typename?: 'PolicyTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PolicyType>;
};

/** An object representing a prioritized prioritized for a given threat profile. */
export type PrioritizedCampaignType = Node &
  StixObject & {
    __typename?: 'PrioritizedCampaignType';
    aliasNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The number of related vulnerabilities. */
    assetCount: Scalars['Int']['output'];
    /** The campaign being prioritized. */
    campaign?: Maybe<CampaignType>;
    campaignId: Scalars['ID']['output'];
    /** The number of detections, preventions, and controls an organization has protecting them from this adversarial campaign. */
    content?: Maybe<Scalars['Int']['output']>;
    countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
    /** Represents the time at which the corresponding campaign object was originally created. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    deprecated?: Maybe<Scalars['Boolean']['output']>;
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** Represents the earliest known date of this campaign. */
    firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
    integration?: Maybe<IntegrationType>;
    /** Represents the last known date this campaign was active. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The source of the campaign or integration name when its from a private campaign. */
    origin: Scalars['String']['output'];
    /** A value representing how much an organization should prioritize implementing this campaign based on their threat profile. */
    priority: Scalars['Int']['output'];
    /** The campaign being prioritized. */
    privateCampaign?: Maybe<PrivateCampaignType>;
    referenceUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    revoked?: Maybe<Scalars['Boolean']['output']>;
    softwareCount?: Maybe<Scalars['Int']['output']>;
    stixId: Scalars['String']['output'];
    techniquesCount?: Maybe<Scalars['Int']['output']>;
    threatGroupsCount?: Maybe<Scalars['Int']['output']>;
    /** The threat profile being used to prioritize the campaign. */
    threatProfile: ThreatProfileType;
    vulnerabilities?: Maybe<Array<Maybe<VulnerabilityType>>>;
    /** The number of related vulnerabilities. */
    vulnerabilityCount: Scalars['Int']['output'];
    vulnerabilityCveIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

export type PrioritizedCampaignTypeConnection = {
  __typename?: 'PrioritizedCampaignTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedCampaignTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedCampaignType` and its cursor. */
export type PrioritizedCampaignTypeEdge = {
  __typename?: 'PrioritizedCampaignTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedCampaignType>;
};

/** An object representing a platform prioritized for a given threat profile and current assets. */
export type PrioritizedPlatformType = Node & {
  __typename?: 'PrioritizedPlatformType';
  assetCount?: Maybe<Scalars['Int']['output']>;
  /** The number of detections, preventions, and controls an organization has protecting these types of platforms. */
  content: Scalars['Int']['output'];
  detectionsCount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** The platform being prioritized. */
  platform: PlatformType;
  prioritizedVulnerabilitiesCount?: Maybe<Scalars['Int']['output']>;
  /** A value representing how much an organization should prioritize patching or defending these types of platforms. */
  priority: Scalars['Int']['output'];
  softwareCount?: Maybe<Scalars['Int']['output']>;
  techniquesCount?: Maybe<Scalars['Int']['output']>;
  /** The threat profile being used to prioritize the platform. */
  threatProfile: ThreatProfileType;
};

export type PrioritizedPlatformTypeConnection = {
  __typename?: 'PrioritizedPlatformTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedPlatformTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedPlatformType` and its cursor. */
export type PrioritizedPlatformTypeEdge = {
  __typename?: 'PrioritizedPlatformTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedPlatformType>;
};

/** An object representing a software prioritized for a given threat profile. */
export type PrioritizedSoftwareType = Node & {
  __typename?: 'PrioritizedSoftwareType';
  aliasNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of detections, preventions, and controls an organization has protecting them from this software/malware. */
  content: Scalars['Int']['output'];
  /** The percentage of available controls for Techniques used by this Software that are currently in place. This value is an integer from 0-100. */
  controlsPercentage: Scalars['Int']['output'];
  countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
  description?: Maybe<Scalars['String']['output']>;
  /** The percentage of available detections for Techniques used by this Software that are currently enabled. This value is an integer from 0-100. */
  detectionsPercentage: Scalars['Int']['output'];
  domainNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
  mitreId?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A value representing how much an organization should prioritize defending against this software/malware  based on their threat profile. */
  priority: Scalars['Int']['output'];
  /** The software being prioritized. */
  software: SoftwareType;
  softwareType?: Maybe<Scalars['String']['output']>;
  techniquesCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available telemetry for Techniques used by this Software that are currently available. This value is an integer from 0-100. */
  telemetryPercentage: Scalars['Int']['output'];
  threatGroupsCount?: Maybe<Scalars['Int']['output']>;
  /** The threat profile being used to prioritize the software. */
  threatProfile: ThreatProfileType;
};

export type PrioritizedSoftwareTypeConnection = {
  __typename?: 'PrioritizedSoftwareTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedSoftwareTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedSoftwareType` and its cursor. */
export type PrioritizedSoftwareTypeEdge = {
  __typename?: 'PrioritizedSoftwareTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedSoftwareType>;
};

export type PrioritizedTechniqueConnection = {
  __typename?: 'PrioritizedTechniqueConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedTechniqueEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedTechnique` and its cursor. */
export type PrioritizedTechniqueEdge = {
  __typename?: 'PrioritizedTechniqueEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedTechniqueType>;
};

/** An object representing a technique prioritized for a given threat profile. */
export type PrioritizedTechniqueType = Node & {
  __typename?: 'PrioritizedTechniqueType';
  alertDetectionsCount?: Maybe<Scalars['Int']['output']>;
  alertsCount?: Maybe<Scalars['Int']['output']>;
  availableTelemetrySubcategoryCount?: Maybe<Scalars['Int']['output']>;
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of detections, preventions, and controls an organization has protecting them from this adversarial technique. */
  content: Scalars['Int']['output'];
  controlsCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available controls for this Technique currently in place. This value is an integer from 0-100. */
  controlsPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Controls Percentage score. */
  controlsPercentageDescription: Scalars['String']['output'];
  countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
  description?: Maybe<Scalars['String']['output']>;
  detectionsCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available detections for this Technique currently enabled. This value is an integer from 0-100. */
  detectionsPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Detection Percentage score. */
  detectionsPercentageDescription: Scalars['String']['output'];
  disabledDetectionsCount?: Maybe<Scalars['Int']['output']>;
  domainNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  enabledDetectionsCount?: Maybe<Scalars['Int']['output']>;
  filters?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  indicatorsCount?: Maybe<Scalars['Int']['output']>;
  industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
  mitreId?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  monitoredTelemetrySubcategoryCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  platforms?: Maybe<Array<Maybe<PlatformType>>>;
  /** A value representing how much an organization should prioritize this adversarial technique based on their threat profile. */
  priority: Scalars['Int']['output'];
  recommendedAction?: Maybe<RecommendedActionType>;
  softwareCount?: Maybe<Scalars['Int']['output']>;
  tacticNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tactics?: Maybe<Array<Maybe<TacticType>>>;
  /** The technique being prioritized. */
  technique: TechniqueType;
  /** The percentage of available telemetry for this Technique currently available. This value is an integer from 0-100. */
  telemetryPercentage: Scalars['Int']['output'];
  /** Human readable calculation of behind the Telemetry Percentage score. */
  telemetryPercentageDescription: Scalars['String']['output'];
  telemetrySubcategoryCount?: Maybe<Scalars['Int']['output']>;
  threatGroupsCount?: Maybe<Scalars['Int']['output']>;
  /** The threat profile being used to prioritize the technique. */
  threatProfile: ThreatProfileType;
  unavailableTelemetrySubcategoryCount?: Maybe<Scalars['Int']['output']>;
};

export type PrioritizedTechniqueTypeConnection = {
  __typename?: 'PrioritizedTechniqueTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedTechniqueTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedTechniqueType` and its cursor. */
export type PrioritizedTechniqueTypeEdge = {
  __typename?: 'PrioritizedTechniqueTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedTechniqueType>;
};

/** An object representing a threat group prioritized for a given threat profile. */
export type PrioritizedThreatGroupType = Node & {
  __typename?: 'PrioritizedThreatGroupType';
  aliasNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  /** The number of detections, preventions, and controls an organization has protecting them from this threat group. */
  content: Scalars['Int']['output'];
  /** The percentage of available controls for Techniques used by this Threat Group that are currently in place. This value is an integer from 0-100. */
  controlsPercentage: Scalars['Int']['output'];
  countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
  description?: Maybe<Scalars['String']['output']>;
  /** The percentage of available detections for Techniques used by this Threat Group that are currently enabled. This value is an integer from 0-100. */
  detectionsPercentage: Scalars['Int']['output'];
  domainNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  highPrioritySoftwareCount?: Maybe<Scalars['Int']['output']>;
  highPriorityTechniquesCount?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
  lowPrioritySoftwareCount?: Maybe<Scalars['Int']['output']>;
  lowPriorityTechniquesCount?: Maybe<Scalars['Int']['output']>;
  mediumPrioritySoftwareCount?: Maybe<Scalars['Int']['output']>;
  mediumPriorityTechniquesCount?: Maybe<Scalars['Int']['output']>;
  mitreId?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A value representing how much an organization should prioritize this threat group based on their threat profile. */
  priority: Scalars['Int']['output'];
  softwareCount?: Maybe<Scalars['Int']['output']>;
  techniquesCount?: Maybe<Scalars['Int']['output']>;
  /** The percentage of available telemetry for Techniques used by this Threat Group that are currently available. This value is an integer from 0-100. */
  telemetryPercentage: Scalars['Int']['output'];
  /** The threat group being prioritized. */
  threatGroup: ThreatGroupType;
  /** The threat profile being used to prioritize the threat group. */
  threatProfile: ThreatProfileType;
  vulnerabilities?: Maybe<VulnerabilityConnection>;
};

/** An object representing a threat group prioritized for a given threat profile. */
export type PrioritizedThreatGroupTypeVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PrioritizedThreatGroupTypeConnection = {
  __typename?: 'PrioritizedThreatGroupTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedThreatGroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedThreatGroupType` and its cursor. */
export type PrioritizedThreatGroupTypeEdge = {
  __typename?: 'PrioritizedThreatGroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedThreatGroupType>;
};

export type PrioritizedVulnerabilityConnection = {
  __typename?: 'PrioritizedVulnerabilityConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedVulnerabilityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedVulnerability` and its cursor. */
export type PrioritizedVulnerabilityEdge = {
  __typename?: 'PrioritizedVulnerabilityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedVulnerabilityType>;
};

/** An object representing a vulnerability prioritized for a given threat profile. */
export type PrioritizedVulnerabilityType = Node & {
  __typename?: 'PrioritizedVulnerabilityType';
  /** Number of assets with this vulnerability, this value is cached. */
  assetCount: Scalars['Int']['output'];
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  cisaKnownExploited?: Maybe<Scalars['Boolean']['output']>;
  configurations?: Maybe<Scalars['GenericScalar']['output']>;
  countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
  cveId?: Maybe<Scalars['String']['output']>;
  cvssSeverityScore?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  epssExploitabilityPercentile?: Maybe<Scalars['Float']['output']>;
  epssExploitabilityScore?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
  integrationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The last time this vulnerability was seen. */
  lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nistExploitabilityScore?: Maybe<Scalars['Float']['output']>;
  platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A value representing how much an organization should prioritize patching or defending against this vulnerability based on their threat profile. */
  priority: Scalars['Int']['output'];
  scannerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The threat profile being used to prioritize the vulnerability. */
  threatProfile: ThreatProfileType;
  /** The vulnerability being prioritized. */
  vulnerability: VulnerabilityType;
};

export type PrioritizedVulnerabilityTypeConnection = {
  __typename?: 'PrioritizedVulnerabilityTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrioritizedVulnerabilityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrioritizedVulnerabilityType` and its cursor. */
export type PrioritizedVulnerabilityTypeEdge = {
  __typename?: 'PrioritizedVulnerabilityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrioritizedVulnerabilityType>;
};

/** An object representing a private campaign. */
export type PrivateCampaignType = Node &
  StixObject & {
    __typename?: 'PrivateCampaignType';
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The domains encompassed within this campaign. */
    domains: DomainTypeConnection;
    /** Represents the time at which this campaign activity was first observed, corresponds to the 'x_mitre_first_seen_citation' field. */
    firstSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    /** Represents the latest observed activity from this campaign, corresponds to the 'x_mitre_last_seen_citation' field. */
    lastSeenTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The origin of the report, e.g. 'MITRE', 'CISA', 'Internal' */
    origin: Scalars['String']['output'];
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** The software or malware contained in this campaign. */
    software: SoftwareTypeConnection;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** The techniques contained in this campaign. */
    techniques: TechniqueTypeConnection;
    /** The threat groups contained in this campaign. */
    threatGroups: ThreatGroupTypeConnection;
    /** The type of report, e.g. 'threat report', 'campaign' */
    type: Scalars['String']['output'];
    /** Known vulnerabilities/CVEs used during this campaign. */
    vulnerabilities: VulnerabilityTypeConnection;
  };

/** An object representing a private campaign. */
export type PrivateCampaignTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a private campaign. */
export type PrivateCampaignTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a private campaign. */
export type PrivateCampaignTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a private campaign. */
export type PrivateCampaignTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a private campaign. */
export type PrivateCampaignTypeVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PrivateCampaignTypeConnection = {
  __typename?: 'PrivateCampaignTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrivateCampaignTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PrivateCampaignType` and its cursor. */
export type PrivateCampaignTypeEdge = {
  __typename?: 'PrivateCampaignTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrivateCampaignType>;
};

/** Interpres GraphQL queries. */
export type Query = {
  __typename?: 'Query';
  activeProductTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of activity feed entries. */
  activityFeed?: Maybe<ActivityFeedEntryTypeConnection>;
  /** Get a list of administrator logs. */
  adminLogs?: Maybe<AdminLogTypeConnection>;
  /** Get a filterable list of asset vulnerabilities. */
  allAssetVulnerabilities?: Maybe<AssetVulnerabilityTypeConnection>;
  /** Get a filterable list of assets. */
  allAssets?: Maybe<AssetTypeConnection>;
  /** Gets a filterable list of detections. */
  allDetections?: Maybe<DetectionTypeConnection>;
  /** Get a filterable list of prioritized campaigns. */
  allPrioritizedCampaigns?: Maybe<PrioritizedCampaignTypeConnection>;
  /** Get a filterable list of prioritized software. */
  allPrioritizedSoftware?: Maybe<PrioritizedSoftwareTypeConnection>;
  /** Get a filterable list of prioritized techniques. */
  allPrioritizedTechniques?: Maybe<PrioritizedTechniqueTypeConnection>;
  /** Get a filterable list of prioritized threat groups. */
  allPrioritizedThreatGroups?: Maybe<PrioritizedThreatGroupTypeConnection>;
  /** Get a filterable list of prioritized vulnerabilities. */
  allPrioritizedVulnerabilities?: Maybe<PrioritizedVulnerabilityTypeConnection>;
  /** Get a filterable list of recommended actions. */
  allRecommendedActions?: Maybe<RecommendedActionTypeConnection>;
  /** Get a filterable list of telemetry subcategories, referred to in the Interpres UI as telemetry. */
  allTelemetrySubcategories?: Maybe<TelemetrySubcategoryTypeConnection>;
  /** Get a filterable list of vulnerabilities. */
  allVulnerabilities?: Maybe<VulnerabilityTypeConnection>;
  app_integrations: Array<Maybe<Integration>>;
  applications: Array<Maybe<Application>>;
  /** Get a filterable list of asset vulnerabilities. */
  assetApplications?: Maybe<AssetApplicationTypeConnection>;
  assetCounts?: Maybe<AssetCountsType>;
  /** Get a filterable list of asset vulnerabilities. */
  assetIps?: Maybe<AssetIpTypeConnection>;
  /** Get a filterable list of asset labels. */
  assetLabels?: Maybe<AssetLabelTypeConnection>;
  assetOperatingSystems?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of asset vulnerabilities. */
  assetVulnerabilities?: Maybe<AssetVulnerabilityTypeConnection>;
  /** Get a filterable list of assets. */
  assets?: Maybe<AssetTypeConnection>;
  /** A list of the platform counts during previous asset uploads or bulk data ingestion. */
  assetsTimeline?: Maybe<Array<Maybe<Scalars['GenericScalar']['output']>>>;
  campaignOrigins?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of campaigns. */
  campaigns?: Maybe<CampaignTypeConnection>;
  categoryNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of chat bot history conversations. */
  chatBotHistories?: Maybe<ChatBotHistoryTypeConnection>;
  collector: RemoteCollectorEntity;
  collectors: ListRemoteCollectorResponse;
  controlOrigins?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of controls. */
  controls?: Maybe<ControlTypeConnection>;
  countries?: Maybe<Array<Maybe<Country>>>;
  currentUser: User;
  dailyScoreHistory?: Maybe<DailyScoreHistory>;
  /** Gets a filterable list of suggested detection technique mappings. */
  detectionTechniqueSuggestions?: Maybe<DetectionTechniqueSuggestionTypeConnection>;
  /** Gets a filterable list of detection technique user mappings. */
  detectionTechniqueUserMappings?: Maybe<DetectionTechniqueUserMappingTypeConnection>;
  /** Gets a filterable list of detection technique vendor mappings. */
  detectionTechniqueVendorMappings?: Maybe<DetectionTechniqueVendorMappingTypeConnection>;
  /** Gets a filterable list of detection types. */
  detectionTypes?: Maybe<DetectionTypeTypeConnection>;
  /** Gets a filterable list of detections. */
  detections?: Maybe<DetectionTypeConnection>;
  /** Get a filterable list of domains. */
  domains?: Maybe<DomainTypeConnection>;
  /** Get error counts per day for the last year. */
  errorCountsTimeline?: Maybe<Array<Maybe<Scalars['GenericScalar']['output']>>>;
  /**
   * DEPRECATED: A list of error timestamps from the last 7 days.
   * @deprecated This query has been replaced by the optimized 'errorCountsTimeline' query.
   */
  errorEventsTimeline?: Maybe<Array<Maybe<Scalars['GenericScalar']['output']>>>;
  exposureScoreHistory?: Maybe<ExposureScoreHistory>;
  groups: ListGroupResponse;
  /** Gets the health status for this GraphQL API. */
  healthStatus?: Maybe<Scalars['Int']['output']>;
  /** Get a filterable list of identities. */
  identities?: Maybe<IdentityTypeConnection>;
  identityDepartmentNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  identityGroupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of identity labels. */
  identityLabels?: Maybe<IdentityLabelTypeConnection>;
  identityLocationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  identityRoleNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  /** Get a filterable list of information models. */
  informationModels?: Maybe<InformationModelTypeConnection>;
  /** Get a filterable list of integration assets. */
  integrationAssets?: Maybe<IntegrationAssetTypeConnection>;
  /** Get a filterable list of integration identities. */
  integrationIdentities?: Maybe<IntegrationIdentityTypeConnection>;
  /** Gets a list of integration modes. */
  integrationModes?: Maybe<IntegrationModeTypeConnection>;
  integrationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of integration specific recommended actions. */
  integrationRecommendedActions?: Maybe<IntegrationRecommendedActionTypeConnection>;
  /** Get a filterable list of integration tags. */
  integrationTags?: Maybe<IntegrationTagTypeConnection>;
  /** Get a filterable list of integration types. */
  integrationTypes?: Maybe<IntegrationTypeTypeConnection>;
  /** Get a filterable list of integrations. */
  integrations?: Maybe<IntegrationTypeConnection>;
  platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of platforms. */
  platforms?: Maybe<PlatformTypeConnection>;
  /** Gets a filterable list of integration policies. */
  policies?: Maybe<PolicyTypeConnection>;
  /** Gets a filterable list of policy rules. */
  policyRules?: Maybe<PolicyRuleTypeConnection>;
  /** Get a filterable list of prioritized campaigns. */
  prioritizedCampaigns?: Maybe<PrioritizedCampaignTypeConnection>;
  /** Get a filterable list of prioritized platforms. */
  prioritizedPlatforms?: Maybe<PrioritizedPlatformTypeConnection>;
  /** Get a filterable list of prioritized software. */
  prioritizedSoftware?: Maybe<PrioritizedSoftwareTypeConnection>;
  /** Get a filterable list of prioritized techniques. */
  prioritizedTechniques?: Maybe<PrioritizedTechniqueTypeConnection>;
  /** Get a filterable list of prioritized threat groups. */
  prioritizedThreatGroups?: Maybe<PrioritizedThreatGroupTypeConnection>;
  /** Get a filterable list of prioritized vulnerabilities. */
  prioritizedVulnerabilities?: Maybe<PrioritizedVulnerabilityTypeConnection>;
  /** Get a filterable list of private campaigns. */
  privateCampaigns?: Maybe<PrivateCampaignTypeConnection>;
  productTypeSummary?: Maybe<IntegrationProductTypeSummary>;
  radarChartScores?: Maybe<RadarChartScores>;
  /** Get a filterable list of recommended actions. */
  recommendedActions?: Maybe<RecommendedActionTypeConnection>;
  /** Get a filterable list of reference detects. */
  referenceDetections?: Maybe<ReferenceDetectionTypeConnection>;
  roles: Array<Maybe<Role>>;
  scannerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  schedules?: Maybe<Schedule>;
  search?: Maybe<SearchResultConnection>;
  /** Get a filterable list of software, referred to in the Interpres UI as malware. */
  software?: Maybe<SoftwareTypeConnection>;
  softwareMitreIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tacticNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of adversarial tactics. */
  tactics?: Maybe<TacticTypeConnection>;
  techniqueMitreIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  techniqueNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of telemetry subcategories, referred to in the Interpres UI as telemetry. */
  techniqueTelemetrySubcategoryRelationship?: Maybe<TechniqueTelemetrySubcategoryRelationshipTypeConnection>;
  /** Get a filterable list of adversarial threats. */
  techniques?: Maybe<TechniqueTypeConnection>;
  /** Get a filterable list of telemetry categories. */
  telemetryCategories?: Maybe<TelemetryCategoryTypeConnection>;
  /** Get a filterable list of telemetry TelemetryIdentifier types. */
  telemetryIdentifierTypes?: Maybe<IdentifierTypeTypeConnection>;
  /** Get a filterable list of telemetry identifiers. */
  telemetryIdentifiers?: Maybe<TelemetryIdentifierTypeConnection>;
  /** Get a filterable list of telemetry subcategories, referred to in the Interpres UI as telemetry. */
  telemetrySubcategories?: Maybe<TelemetrySubcategoryTypeConnection>;
  tenantChildren: ListChildrenResponse;
  threatGroupMitreIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get a filterable list of threat groups, referred to in the Interpres UI as adversaries. */
  threatGroups?: Maybe<ThreatGroupTypeConnection>;
  /** Get a filterable list of threat profiles. */
  threatProfiles?: Maybe<ThreatProfileTypeConnection>;
  threatSummary?: Maybe<ThreatSummary>;
  /** Get a filterable list of ticket entries. */
  tickets?: Maybe<TicketTypeConnection>;
  token?: Maybe<TokenType>;
  /** Cached daily trend data. */
  trendData?: Maybe<DailyTrendDataTypeConnection>;
  trends?: Maybe<TrendsType>;
  users: ListUserResponse;
  /** Get a filterable list of vulnerabilities. */
  vulnerabilities?: Maybe<VulnerabilityTypeConnection>;
};

/** Interpres GraphQL queries. */
export type QueryActivityFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integration?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAdminLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<Scalars['String']['input']>;
  component_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  ip_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  message_Icontains?: InputMaybe<Scalars['String']['input']>;
  minimumLevel?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllAssetVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationAsset_Id?: InputMaybe<Scalars['ID']['input']>;
  integrationAsset_Name?: InputMaybe<Scalars['String']['input']>;
  integrationAsset_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  vulnerability_CveId?: InputMaybe<Scalars['String']['input']>;
  vulnerability_Id?: InputMaybe<Scalars['ID']['input']>;
  vulnerability_Name?: InputMaybe<Scalars['String']['input']>;
  vulnerability_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  critical?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  exposure?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  hostname_Icontains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityDepartments?: InputMaybe<Scalars['String']['input']>;
  identityEmailDomain?: InputMaybe<Scalars['String']['input']>;
  identityGroups?: InputMaybe<Scalars['String']['input']>;
  identityLabels?: InputMaybe<Scalars['String']['input']>;
  identityLocations?: InputMaybe<Scalars['String']['input']>;
  identityRoles?: InputMaybe<Scalars['String']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  isVulnerable?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Scalars['String']['input']>;
  labelsV2?: InputMaybe<LabelFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  operatingSystem_Icontains?: InputMaybe<Scalars['String']['input']>;
  operatingSystems?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  scannerIds?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNamesOrDescription?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_Icontains?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  definitionSources?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  detailedSearch?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  indicatorCount?: InputMaybe<Scalars['String']['input']>;
  indicatorsCount?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationTags?: InputMaybe<Scalars['String']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  license_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notional?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prevention?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  severities?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<DetectionsDetectionSeverityChoices>;
  severity_Icontains?: InputMaybe<DetectionsDetectionSeverityChoices>;
  state?: InputMaybe<DetectionsDetectionStateChoices>;
  stateChangedDays?: InputMaybe<Scalars['Int']['input']>;
  state_Icontains?: InputMaybe<DetectionsDetectionStateChoices>;
  states?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  techniquesCount?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllPrioritizedCampaignsArgs = {
  activeThreatProfile?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExploits?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limitCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_Icontains?: InputMaybe<Scalars['String']['input']>;
  origins?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnlyWithExploits?: InputMaybe<Scalars['Boolean']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  stixId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroupsMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatProfile?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllPrioritizedSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  mitreIds?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareType?: InputMaybe<Scalars['String']['input']>;
  softwareTypes?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroups?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllPrioritizedTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  controlStixIds?: InputMaybe<Scalars['String']['input']>;
  controlsPercentage?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  detectionsPercentage?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  mitreIds?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  tacticName?: InputMaybe<Scalars['String']['input']>;
  tacticNames?: InputMaybe<Scalars['String']['input']>;
  telemetryPercentage?: InputMaybe<Scalars['String']['input']>;
  telemetrySubcategoryId?: InputMaybe<Scalars['ID']['input']>;
  telemetrySubcategoryName?: InputMaybe<Scalars['String']['input']>;
  telemetrySubcategoryNames?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllPrioritizedThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllPrioritizedVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  assetId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignIds?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  cisaKnownExploited?: InputMaybe<Scalars['Boolean']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  cveId?: InputMaybe<Scalars['String']['input']>;
  cveId_Icontains?: InputMaybe<Scalars['String']['input']>;
  cvssSeverityScore?: InputMaybe<Scalars['String']['input']>;
  epssExploitabilityScore?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityDepartments?: InputMaybe<Scalars['String']['input']>;
  identityGroups?: InputMaybe<Scalars['String']['input']>;
  identityLabels?: InputMaybe<Scalars['String']['input']>;
  identityLocations?: InputMaybe<Scalars['String']['input']>;
  identityRoles?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  knownAssociatedCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastSeenTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  scannerIds?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllRecommendedActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<ThreatprofilesRecommendedActionCategoryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ThreatprofilesRecommendedActionStatusChoices>;
  statuses?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryNames?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasAnyCollectedData?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnyNotionalData?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationName?: InputMaybe<Scalars['String']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAllVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cveId?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryApp_IntegrationsArgs = {
  integrationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryApplicationsArgs = {
  appIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Interpres GraphQL queries. */
export type QueryAssetApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAssetIpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAssetLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  origin?: InputMaybe<AssetsAssetLabelOriginChoices>;
};

/** Interpres GraphQL queries. */
export type QueryAssetVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationAsset_Id?: InputMaybe<Scalars['ID']['input']>;
  integrationAsset_Name?: InputMaybe<Scalars['String']['input']>;
  integrationAsset_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  vulnerability_CveId?: InputMaybe<Scalars['String']['input']>;
  vulnerability_Id?: InputMaybe<Scalars['ID']['input']>;
  vulnerability_Name?: InputMaybe<Scalars['String']['input']>;
  vulnerability_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  critical?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  exposure?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  hostname_Icontains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityDepartments?: InputMaybe<Scalars['String']['input']>;
  identityEmailDomain?: InputMaybe<Scalars['String']['input']>;
  identityGroups?: InputMaybe<Scalars['String']['input']>;
  identityLabels?: InputMaybe<Scalars['String']['input']>;
  identityLocations?: InputMaybe<Scalars['String']['input']>;
  identityRoles?: InputMaybe<Scalars['String']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  isVulnerable?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Scalars['String']['input']>;
  labelsV2?: InputMaybe<LabelFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  operatingSystem_Icontains?: InputMaybe<Scalars['String']['input']>;
  operatingSystems?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  scannerIds?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNamesOrDescription?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExploits?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_Icontains?: InputMaybe<Scalars['String']['input']>;
  origins?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroupsMitreIds?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_Icontains?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryChatBotHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryCollectorArgs = {
  id: Scalars['String']['input'];
  includeSecret?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryCollectorsArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryControlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_Icontains?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  referenceId_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDetectionTechniqueSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  score_Gt?: InputMaybe<Scalars['Int']['input']>;
  score_Gte?: InputMaybe<Scalars['Int']['input']>;
  score_Lt?: InputMaybe<Scalars['Int']['input']>;
  score_Lte?: InputMaybe<Scalars['Int']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDetectionTechniqueUserMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDetectionTechniqueVendorMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDetectionTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationType?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_Icontains?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  definitionSources?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  detailedSearch?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  indicatorCount?: InputMaybe<Scalars['String']['input']>;
  indicatorsCount?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  indicatorsCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationTags?: InputMaybe<Scalars['String']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  license_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notional?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prevention?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  severities?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<DetectionsDetectionSeverityChoices>;
  severity_Icontains?: InputMaybe<DetectionsDetectionSeverityChoices>;
  state?: InputMaybe<DetectionsDetectionStateChoices>;
  stateChangedDays?: InputMaybe<Scalars['Int']['input']>;
  state_Icontains?: InputMaybe<DetectionsDetectionStateChoices>;
  states?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  techniquesCount?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  techniquesCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryGroupsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  groupid?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  departments?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groups?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  labels?: InputMaybe<Scalars['String']['input']>;
  labelsV2?: InputMaybe<LabelFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Scalars['String']['input']>;
  searchNamesOrDescription?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIdentityDepartmentNamesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIdentityGroupNamesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIdentityLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  origin?: InputMaybe<IdentitiesIdentityLabelOriginChoices>;
};

/** Interpres GraphQL queries. */
export type QueryIdentityLocationNamesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIdentityRoleNamesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryInformationModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationModesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationRecommendedActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<IntegrationsIntegrationRecommendedActionCategoryChoices>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IntegrationsIntegrationRecommendedActionStatusChoices>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
  productTypes?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasDetectionsOrTelemetry?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationType_ProductType?: InputMaybe<Scalars['String']['input']>;
  integrationType_ProductType_Icontains?: InputMaybe<
    Scalars['String']['input']
  >;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productTypes?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPoliciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PoliciesPolicyStatusChoices>;
};

/** Interpres GraphQL queries. */
export type QueryPolicyRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<IntegrationtypesPolicyRuleSeverityChoices>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedCampaignsArgs = {
  activeThreatProfile?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExploits?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limitCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_Icontains?: InputMaybe<Scalars['String']['input']>;
  origins?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnlyWithExploits?: InputMaybe<Scalars['Boolean']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  stixId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroupsMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatProfile?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedPlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  mitreIds?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareType?: InputMaybe<Scalars['String']['input']>;
  softwareTypes?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
  threatGroups?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  controlStixIds?: InputMaybe<Scalars['String']['input']>;
  controlsPercentage?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  detectionsPercentage?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  mitreIds?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  tacticName?: InputMaybe<Scalars['String']['input']>;
  tacticNames?: InputMaybe<Scalars['String']['input']>;
  telemetryPercentage?: InputMaybe<Scalars['String']['input']>;
  telemetrySubcategoryId?: InputMaybe<Scalars['ID']['input']>;
  telemetrySubcategoryName?: InputMaybe<Scalars['String']['input']>;
  telemetrySubcategoryNames?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  detectionId?: InputMaybe<Scalars['ID']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNameOrMitreId?: InputMaybe<Scalars['String']['input']>;
  softwareMitreIds?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrioritizedVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetCount_Gt?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Gte?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Lt?: InputMaybe<Scalars['Int']['input']>;
  assetCount_Lte?: InputMaybe<Scalars['Int']['input']>;
  assetId?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaignIds?: InputMaybe<Scalars['String']['input']>;
  campaignStixIds?: InputMaybe<Scalars['String']['input']>;
  cisaKnownExploited?: InputMaybe<Scalars['Boolean']['input']>;
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  cveId?: InputMaybe<Scalars['String']['input']>;
  cveId_Icontains?: InputMaybe<Scalars['String']['input']>;
  cvssSeverityScore?: InputMaybe<Scalars['String']['input']>;
  epssExploitabilityScore?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityDepartments?: InputMaybe<Scalars['String']['input']>;
  identityGroups?: InputMaybe<Scalars['String']['input']>;
  identityLabels?: InputMaybe<Scalars['String']['input']>;
  identityLocations?: InputMaybe<Scalars['String']['input']>;
  identityRoles?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  knownAssociatedCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastSeenTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeenTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  scannerIds?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  threatGroupMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryPrivateCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryRecommendedActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<ThreatprofilesRecommendedActionCategoryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ThreatprofilesRecommendedActionStatusChoices>;
  statuses?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryReferenceDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  detailedSearch?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_Icontains?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryScannerIdsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QuerySchedulesArgs = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QuerySearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QuerySoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
  softwareType?: InputMaybe<ThreatsSoftwareSoftwareTypeChoices>;
  softwareTypes?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTacticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTechniqueTelemetrySubcategoryRelationshipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
  telemetrySubcategoryId?: InputMaybe<Scalars['ID']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTelemetryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTelemetryIdentifierTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationType?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  static?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTelemetryIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  definitionSources?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integration_Id?: InputMaybe<Scalars['ID']['input']>;
  integration_Name?: InputMaybe<Scalars['String']['input']>;
  integration_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryNames?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasAnyCollectedData?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnyNotionalData?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  integrationName?: InputMaybe<Scalars['String']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreId?: InputMaybe<Scalars['String']['input']>;
  techniqueMitreIds?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  domainName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mitreId?: InputMaybe<Scalars['String']['input']>;
  mitreId_Icontains?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryThreatProfilesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  modifiedTimestamp_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedTimestamp_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedTimestamp_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedTimestamp_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  referenceId_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTrendDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  date_Gt?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lt?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryTrendsArgs = {
  days: Scalars['Int']['input'];
};

/** Interpres GraphQL queries. */
export type QueryUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userid?: InputMaybe<Scalars['String']['input']>;
};

/** Interpres GraphQL queries. */
export type QueryVulnerabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cveId?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  prioritizedCampaignId?: InputMaybe<Scalars['ID']['input']>;
};

export type RadarChartScores = {
  __typename?: 'RadarChartScores';
  /** The most coverage scores. Note, hight the score the better the coverage. */
  currentScores?: Maybe<RadarChartScoresEntry>;
  /** The previous coverage scores from 30 days ago. */
  previousScores?: Maybe<RadarChartScoresEntry>;
};

export type RadarChartScoresEntry = {
  __typename?: 'RadarChartScoresEntry';
  /** The date string in YYYY-MM-DD format. */
  date?: Maybe<Scalars['String']['output']>;
  /** The weighted average content for the top 20 pieces of software. */
  softwareScore?: Maybe<Scalars['Int']['output']>;
  /** The weighted average content for the top 20 techniques. */
  techniqueScore?: Maybe<Scalars['Int']['output']>;
  /** A telemetry coverage score derived from the weighted average of telemetry subcategories with available telemetry. */
  telemetryScore?: Maybe<Scalars['Int']['output']>;
  /** The weighted average content for the top 20 threat groups. */
  threatGroupScore?: Maybe<Scalars['Int']['output']>;
  /** A vulnerability coverage score derived from the average priority of the top 100 active vulnerabilities. */
  vulnerabilityScore?: Maybe<Scalars['Int']['output']>;
};

export type ReactivateUser = {
  userId: Scalars['String']['input'];
};

/** An action that can be used to improve an organizations security posture. */
export type RecommendedActionType = Node & {
  __typename?: 'RecommendedActionType';
  /** A basic categorization for sorting and filtering as well as determining what object to link to using the reference id. */
  category: ThreatprofilesRecommendedActionCategoryChoices;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents the time at which the source of this action was last modified. */
  lastCheckedTimestamp: Scalars['DateTime']['output'];
  /** An organization impact score based on their Threat Profile. */
  localImpact: Scalars['Float']['output'];
  /** Represents the time at which this object was last modified. */
  modifiedTimestamp: Scalars['DateTime']['output'];
  /** The name or a short description of the action. */
  name: Scalars['String']['output'];
  /** A value used to help calculate the rank. */
  priorityScore: Scalars['Float']['output'];
  /** A value representing how complete this action is in the form of a percentage. */
  progress: Scalars['Int']['output'];
  /** The numeric rank of importance of this action in ascending order. */
  rank?: Maybe<Scalars['Int']['output']>;
  reference?: Maybe<StixObject>;
  /** An ID used to reference a specific model's object based on this actions category. */
  referenceId: Scalars['String']['output'];
  referenceName?: Maybe<Scalars['String']['output']>;
  /** Human readable impact score. */
  score: Scalars['Float']['output'];
  /** Current completion status of this action. */
  status: ThreatprofilesRecommendedActionStatusChoices;
  /** An organization impact score that does not take into affect their Threat Profile. */
  universalImpact: Scalars['Float']['output'];
};

export type RecommendedActionTypeConnection = {
  __typename?: 'RecommendedActionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecommendedActionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RecommendedActionType` and its cursor. */
export type RecommendedActionTypeEdge = {
  __typename?: 'RecommendedActionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RecommendedActionType>;
};

/** An object representing a reference detection. */
export type ReferenceDetectionType = Node &
  StixObject & {
    __typename?: 'ReferenceDetectionType';
    /** The author of this detection. */
    author?: Maybe<Scalars['String']['output']>;
    /** A score provided by the detection's author representing the fidelity or likelihood this detection is to produce a true positive. */
    confidence?: Maybe<Scalars['Float']['output']>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The primary domains this reference detection can be used. */
    domains: DomainTypeConnection;
    id: Scalars['ID']['output'];
    /** A score provided by the detection's author representing the severity of a true positive alert. */
    impact?: Maybe<Scalars['Float']['output']>;
    /** Any license associated with this detection. */
    license?: Maybe<Scalars['String']['output']>;
    /** The logic for this detection. */
    logic?: Maybe<Scalars['JSONString']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The origin of this reference detection, e.g. 'Sigma', 'MITRE', 'Elastic' */
    origin: Scalars['String']['output'];
    /** A unique ID representing this detection, most commonly an internal unique ID from the provider/origin of the reference detection. */
    referenceId: Scalars['String']['output'];
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** A score provided by the detection's author that combines the fidelity and danger associated with a true positive alert. */
    riskScore?: Maybe<Scalars['Float']['output']>;
    /** The version number for this detection. */
    severity: ThreatsReferenceDetectionSeverityChoices;
    /** The software this reference detection helps detect. */
    software: SoftwareTypeConnection;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    techniqueMitreIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The adversarial techniques this reference detection helps detect. */
    techniques: TechniqueTypeConnection;
    /** The telemetry subcategories this reference detection requires. */
    telemetrySubcategories: TelemetrySubcategoryTypeConnection;
    /** The threat groups this reference detection helps detect. */
    threatGroups: ThreatGroupTypeConnection;
    /** The version number for this detection. */
    version?: Maybe<Scalars['String']['output']>;
  };

/** An object representing a reference detection. */
export type ReferenceDetectionTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a reference detection. */
export type ReferenceDetectionTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a reference detection. */
export type ReferenceDetectionTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a reference detection. */
export type ReferenceDetectionTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a reference detection. */
export type ReferenceDetectionTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferenceDetectionTypeConnection = {
  __typename?: 'ReferenceDetectionTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReferenceDetectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ReferenceDetectionType` and its cursor. */
export type ReferenceDetectionTypeEdge = {
  __typename?: 'ReferenceDetectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ReferenceDetectionType>;
};

export type RemoteCollectorEntity = {
  __typename?: 'RemoteCollectorEntity';
  entityId: Scalars['String']['output'];
  last_checked_in?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  remote_ip?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
  status: Status;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type RemoveGroupMembership = {
  groupIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type RemoveLabelFromAsset = {
  __typename?: 'RemoveLabelFromAsset';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type RemoveLabelFromAssetInput = {
  /** The asset to apply the label to. */
  assetId: Scalars['String']['input'];
  /** The label to be applied. */
  label: Scalars['String']['input'];
};

export type RemoveLabelFromIdentity = {
  __typename?: 'RemoveLabelFromIdentity';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type RemoveLabelFromIdentityInput = {
  /** The identity to apply the label to. */
  identityId: Scalars['String']['input'];
  /** The label to be applied. */
  label: Scalars['String']['input'];
};

/** Remove a tag from an integration. */
export type RemoveTagFromIntegration = {
  __typename?: 'RemoveTagFromIntegration';
  integration?: Maybe<IntegrationType>;
};

export type RemoveTagFromIntegrationInput = {
  /** ID of the integration to untag. */
  integrationId: Scalars['ID']['input'];
  /** ID of the tag to remove from the integration. */
  tagId: Scalars['ID']['input'];
};

/** Remove a list of tags from an integration. */
export type RemoveTagsFromIntegration = {
  __typename?: 'RemoveTagsFromIntegration';
  integration?: Maybe<IntegrationType>;
};

export type RemoveTagsFromIntegrationInput = {
  /** ID of the integration to untag. */
  integrationId: Scalars['ID']['input'];
  /** List of case sensitive tag names, unknown tag names will be ignored. */
  names: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
};

/** Rolls the admin logs. */
export type RollLogs = {
  __typename?: 'RollLogs';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** Schedules the specified action to run on the integration. **NOT FUNCTIONAL** */
export type RunActionOnIntegration = {
  __typename?: 'RunActionOnIntegration';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type RunActionOnIntegrationInput = {
  /** The action to run. */
  action: IntegrationActions;
  /** ID of the integration to run the action on. */
  integrationId: Scalars['ID']['input'];
};

/** A Structured Threat Information eXpression (STIX) object. */
export type StixObject = {
  /** Description of the STIX object. */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the STIX object. */
  name: Scalars['String']['output'];
  /** Structured Threat Information eXpression ID. */
  stixId: Scalars['String']['output'];
};

export type Schedule = {
  __typename?: 'Schedule';
  /**  Schedule for each action  */
  actionSchedules: Array<Maybe<ScheduledAction>>;
};

export enum ScheduleFrequency {
  Daily = 'Daily',
  Hourly = 'Hourly',
  Internal = 'Internal',
  Weekly = 'Weekly'
}

export type ScheduledAction = {
  __typename?: 'ScheduledAction';
  /**  The Action to run  */
  actionIdentifier: Scalars['String']['output'];
  /**  How often to run it  */
  fequency: ScheduleFrequency;
  /** The integration id */
  integrationId: Scalars['String']['output'];
  /** The schedule priority */
  priority?: Maybe<Scalars['String']['output']>;
};

export type SearchResultConnection = {
  __typename?: 'SearchResultConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchResultEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SearchResult` and its cursor. */
export type SearchResultEdge = {
  __typename?: 'SearchResultEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SearchResultUnion>;
};

export type SearchResultUnion =
  | AssetType
  | DetectionType
  | IdentityType
  | PrioritizedCampaignType
  | PrioritizedSoftwareType
  | PrioritizedTechniqueType
  | PrioritizedThreatGroupType
  | PrioritizedVulnerabilityType
  | TelemetrySubcategoryType;

export type SeverityThreatSummary = {
  __typename?: 'SeverityThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
  dataType?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
};

/** An alternative name for a piece of software. */
export type SoftwareAliasType = {
  __typename?: 'SoftwareAliasType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SoftwareThreatSummary = {
  __typename?: 'SoftwareThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
  severityDistribution?: Maybe<Array<Maybe<SeverityThreatSummary>>>;
};

/** An object representing a software tool or malware. */
export type SoftwareType = Node &
  StixObject & {
    __typename?: 'SoftwareType';
    aliases: Array<SoftwareAliasType>;
    /** The software or malware contained in this campaign. */
    campaigns: CampaignTypeConnection;
    content?: Maybe<Scalars['Int']['output']>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The software this detection helps detect. */
    detections: DetectionTypeConnection;
    /** The domains in which this software is used. */
    domains: DomainTypeConnection;
    id: Scalars['ID']['output'];
    /** The MITRE provided ID. */
    mitreId?: Maybe<Scalars['String']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The platforms this software normally executes on. */
    platforms: PlatformTypeConnection;
    priority?: Maybe<Scalars['Int']['output']>;
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** The types of software, e.g. 'Tool', 'Malware'. */
    softwareType: ThreatsSoftwareSoftwareTypeChoices;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** The techniques this software is known to use. */
    techniques: TechniqueTypeConnection;
    /** Threat Groups have been observed using specific software/malware. */
    threatGroups: ThreatGroupTypeConnection;
    /** A value representing how much an organization should prioritize this software/malware based on their threat profile. This value does not take existing protections into consideration. */
    universalPriority: Scalars['Int']['output'];
    /** Determines if the object should be overwritten on change. */
    update: Scalars['Boolean']['output'];
  };

/** An object representing a software tool or malware. */
export type SoftwareTypeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a software tool or malware. */
export type SoftwareTypeDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a software tool or malware. */
export type SoftwareTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a software tool or malware. */
export type SoftwareTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a software tool or malware. */
export type SoftwareTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a software tool or malware. */
export type SoftwareTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SoftwareTypeConnection = {
  __typename?: 'SoftwareTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SoftwareTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SoftwareType` and its cursor. */
export type SoftwareTypeEdge = {
  __typename?: 'SoftwareTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SoftwareType>;
};

export enum Status {
  Archived = 'ARCHIVED',
  Connected = 'Connected',
  Disabled = 'DISABLED',
  Disconnected = 'Disconnected',
  Enabled = 'ENABLED',
  Unknown = 'Unknown'
}

/** The reason an adversary is performing a action, commonly used to categorize techniques. */
export type TacticType = Node & {
  __typename?: 'TacticType';
  /** A description of what the object represents. */
  description?: Maybe<Scalars['String']['output']>;
  /** The domains in which this tactic belongs. */
  domains: DomainTypeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The MITRE provided ID. */
  mitreId: Scalars['String']['output'];
  /** The name of the STIX object. */
  name: Scalars['String']['output'];
};

/** The reason an adversary is performing a action, commonly used to categorize techniques. */
export type TacticTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TacticTypeConnection = {
  __typename?: 'TacticTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TacticTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TacticType` and its cursor. */
export type TacticTypeEdge = {
  __typename?: 'TacticTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TacticType>;
};

export type TechniqueConnection = {
  __typename?: 'TechniqueConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TechniqueEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `Technique` and its cursor. */
export type TechniqueEdge = {
  __typename?: 'TechniqueEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TechniqueType>;
};

/** A descrioption of how a Telemetry Subcategory can be used to detect a Technique. */
export type TechniqueTelemetrySubcategoryRelationshipType = Node & {
  __typename?: 'TechniqueTelemetrySubcategoryRelationshipType';
  /** MITRE ATT&CK provides a description for the Telemetry SubCategory -> Technique */
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The technique that this belongs to. */
  technique: TechniqueType;
  /** The telemetry subcategory that this belongs to. */
  telemetrySubcategory: TelemetrySubcategoryType;
};

export type TechniqueTelemetrySubcategoryRelationshipTypeConnection = {
  __typename?: 'TechniqueTelemetrySubcategoryRelationshipTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TechniqueTelemetrySubcategoryRelationshipTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TechniqueTelemetrySubcategoryRelationshipType` and its cursor. */
export type TechniqueTelemetrySubcategoryRelationshipTypeEdge = {
  __typename?: 'TechniqueTelemetrySubcategoryRelationshipTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TechniqueTelemetrySubcategoryRelationshipType>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueType = Node &
  StixObject & {
    __typename?: 'TechniqueType';
    /** The techniques contained in this campaign. */
    campaigns: CampaignTypeConnection;
    content?: Maybe<Scalars['Int']['output']>;
    /** The techniques this control helps mitigate. */
    controls: ControlTypeConnection;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The adversarial techniques this detection helps detect. */
    detections: DetectionTypeConnection;
    /** The domains in which this technique can be applied. */
    domains: DomainTypeConnection;
    id: Scalars['ID']['output'];
    /** The MITRE provided ID. */
    mitreId: Scalars['String']['output'];
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** The platforms this technique applies to. */
    platforms: PlatformTypeConnection;
    priority?: Maybe<Scalars['Int']['output']>;
    /** The adversarial techniques this reference detection helps detect. */
    referenceDetections: ReferenceDetectionTypeConnection;
    /** The techniques this software is known to use. */
    software: SoftwareTypeConnection;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    subtechnique?: Maybe<Scalars['Boolean']['output']>;
    /** Tactics are goals that a Technique helps an adversary achieve. */
    tactics: TacticTypeConnection;
    /** The adversarial techniques this telemetry helps monitor. */
    telemetrySubcategories: TelemetrySubcategoryTypeConnection;
    /** Threat Groups have been observed using specific Techniques. */
    threatGroups: ThreatGroupTypeConnection;
    /** The default priority of this technique. This value does not take into consideration the customers threat profile nor existing protections. */
    universalPriority: Scalars['Int']['output'];
    /** Determines if the object should be overwritten on change. */
    update: Scalars['Boolean']['output'];
  };

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeControlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeReferenceDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeTacticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A MITRE ATT&CK Technique object. */
export type TechniqueTypeThreatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TechniqueTypeConnection = {
  __typename?: 'TechniqueTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TechniqueTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TechniqueType` and its cursor. */
export type TechniqueTypeEdge = {
  __typename?: 'TechniqueTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TechniqueType>;
};

export type TechniquesThreatSummary = {
  __typename?: 'TechniquesThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
  severityDistribution?: Maybe<Array<Maybe<SeverityThreatSummary>>>;
};

/** A category used for grouping and sorting telemetry provided by an integration. */
export type TelemetryCategoryType = Node & {
  __typename?: 'TelemetryCategoryType';
  /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** A description of what the object represents. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the STIX object. */
  name: Scalars['String']['output'];
  /** The telemetry categories in which this subcategory belongs. */
  telemetrySubcategories: TelemetrySubcategoryTypeConnection;
};

/** A category used for grouping and sorting telemetry provided by an integration. */
export type TelemetryCategoryTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TelemetryCategoryTypeConnection = {
  __typename?: 'TelemetryCategoryTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TelemetryCategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TelemetryCategoryType` and its cursor. */
export type TelemetryCategoryTypeEdge = {
  __typename?: 'TelemetryCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TelemetryCategoryType>;
};

export type TelemetryIdentifierConnection = {
  __typename?: 'TelemetryIdentifierConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TelemetryIdentifierEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TelemetryIdentifier` and its cursor. */
export type TelemetryIdentifierEdge = {
  __typename?: 'TelemetryIdentifierEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TelemetryIdentifierType>;
};

/** The data needed to identify that one or more subcategories of telemetry are available for an integration. */
export type TelemetryIdentifierType = Node & {
  __typename?: 'TelemetryIdentifierType';
  /** If the identifier found the specified telemetry. */
  available: Scalars['Boolean']['output'];
  /** A timestamp used to determine how long this telemetry has been available. Returns to null when no longer available. */
  availableSince?: Maybe<Scalars['DateTime']['output']>;
  /** The arguments that correspond to the TelemetryIdentifier type's parameters. */
  config?: Maybe<Scalars['GenericScalar']['output']>;
  /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The data source for the detection */
  definitionSource?: Maybe<DefinitionSourceType>;
  definitionSourceName?: Maybe<Scalars['String']['output']>;
  /** A description of what the object represents. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The type of identifier. */
  identifierType: IdentifierTypeType;
  /** The integration that this identifier is run on. */
  integration: IntegrationType;
  integrationName?: Maybe<Scalars['String']['output']>;
  /** The last time this telemetry was identified. It defaults to null if it has never been identified. */
  lastAvailable?: Maybe<Scalars['DateTime']['output']>;
  /** The last time this telemetry was checked for on the integration. It defaults to null if it has never been checked. */
  lastChecked?: Maybe<Scalars['DateTime']['output']>;
  /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** If the identifier is configured to continuously monitor the telemetry on an integration. */
  monitored: Scalars['Boolean']['output'];
  /** The name of the STIX object. */
  name: Scalars['String']['output'];
  notional?: Maybe<Scalars['Boolean']['output']>;
  /** The platforms this telemetry is associated with, normally only one. */
  platforms: PlatformTypeConnection;
  techniques?: Maybe<TechniqueConnection>;
  /** The telemetry subcategories the identified telemetry provides. */
  telemetrySubcategories: TelemetrySubcategoryTypeConnection;
  telemetrySubcategoryLinks?: Maybe<Array<Maybe<TelemetrySubcategoryLink>>>;
};

/** The data needed to identify that one or more subcategories of telemetry are available for an integration. */
export type TelemetryIdentifierTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The data needed to identify that one or more subcategories of telemetry are available for an integration. */
export type TelemetryIdentifierTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The data needed to identify that one or more subcategories of telemetry are available for an integration. */
export type TelemetryIdentifierTypeTelemetrySubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TelemetryIdentifierTypeConnection = {
  __typename?: 'TelemetryIdentifierTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TelemetryIdentifierTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TelemetryIdentifierType` and its cursor. */
export type TelemetryIdentifierTypeEdge = {
  __typename?: 'TelemetryIdentifierTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TelemetryIdentifierType>;
};

export type TelemetrySubcategoryLink = {
  __typename?: 'TelemetrySubcategoryLink';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryType = Node &
  StixObject & {
    __typename?: 'TelemetrySubcategoryType';
    availableIdentifiersCount?: Maybe<Scalars['Int']['output']>;
    availableTelemetryIdentifiers?: Maybe<TelemetryIdentifierConnection>;
    categoryName?: Maybe<Scalars['String']['output']>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The domains in which this telemetry is applicable. */
    domains: DomainTypeConnection;
    highPriorityTechniques?: Maybe<PrioritizedTechniqueConnection>;
    id: Scalars['ID']['output'];
    identifiersAvailability?: Maybe<Array<Maybe<Scalars['Boolean']['output']>>>;
    identifiersCount?: Maybe<Scalars['Int']['output']>;
    integrationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    integrations?: Maybe<IntegrationConnection>;
    isAvailable?: Maybe<Scalars['Boolean']['output']>;
    isHealthy?: Maybe<Scalars['Boolean']['output']>;
    isMonitored?: Maybe<Scalars['Boolean']['output']>;
    lastCheckedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    lowPriorityTechniques?: Maybe<PrioritizedTechniqueConnection>;
    mediumPriorityTechniques?: Maybe<PrioritizedTechniqueConnection>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    platformNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The platforms this telemetry can be obtained from. */
    platforms: PlatformTypeConnection;
    /** A value representing how much an organization should prioritize this Telemetry. This value does not take into consideration an organizations Threat Profile nor existing telemetry. */
    priority: Scalars['Int']['output'];
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    techniqueRelationshipDescription?: Maybe<
      Array<Maybe<TechniqueTelemetrySubcategoryRelationshipType>>
    >;
    /** The adversarial techniques this telemetry helps monitor. */
    techniques: TechniqueTypeConnection;
    techniquesCount?: Maybe<Scalars['Int']['output']>;
    /** The telemetry categories in which this subcategory belongs. */
    telemetryCategory?: Maybe<TelemetryCategoryType>;
    /** The telemetry subcategories the identified telemetry provides. */
    telemetryIdentifiers: TelemetryIdentifierTypeConnection;
    unavailableTelemetryIdentifiers?: Maybe<TelemetryIdentifierConnection>;
  };

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeAvailableTelemetryIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeHighPriorityTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeLowPriorityTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeMediumPriorityTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeTelemetryIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** A granular categorization used for grouping and sorting telemetry provided by an integration. */
export type TelemetrySubcategoryTypeUnavailableTelemetryIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TelemetrySubcategoryTypeConnection = {
  __typename?: 'TelemetrySubcategoryTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TelemetrySubcategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TelemetrySubcategoryType` and its cursor. */
export type TelemetrySubcategoryTypeEdge = {
  __typename?: 'TelemetrySubcategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TelemetrySubcategoryType>;
};

/** An alternative name for a threat group. */
export type ThreatGroupAliasType = {
  __typename?: 'ThreatGroupAliasType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An object representing a threat group/adversary. */
export type ThreatGroupType = Node &
  StixObject & {
    __typename?: 'ThreatGroupType';
    aliases: Array<ThreatGroupAliasType>;
    /** The threat groups contained in this campaign. */
    campaigns: CampaignTypeConnection;
    content?: Maybe<Scalars['Int']['output']>;
    /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
    createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** A MITRE ATT&CK specific field that indicates that an object is no longer used in the latest version of the MITRE ATT&CK Matrix. */
    deprecated: Scalars['Boolean']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** The threat groups this detection helps detect. */
    detections: DetectionTypeConnection;
    /** The domains in which this threat group targets. */
    domains: DomainTypeConnection;
    id: Scalars['ID']['output'];
    /** The MITRE provided ID. */
    mitreId?: Maybe<Scalars['String']['output']>;
    /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
    modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    priority?: Maybe<Scalars['Int']['output']>;
    /** Indicates if the object should not longer be used. Be careful to filter out revoked objects. */
    revoked: Scalars['Boolean']['output'];
    /** Threat Groups have been observed using specific software/malware. */
    software: SoftwareTypeConnection;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** Threat Groups have been observed using specific Techniques. */
    techniques: TechniqueTypeConnection;
    /** A value representing how much an organization should prioritize this threat group based on their threat profile. This value does not take existing protections into consideration. */
    universalPriority: Scalars['Int']['output'];
    /** Determines if the object should be overwritten on change. */
    update: Scalars['Boolean']['output'];
  };

/** An object representing a threat group/adversary. */
export type ThreatGroupTypeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a threat group/adversary. */
export type ThreatGroupTypeDetectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a threat group/adversary. */
export type ThreatGroupTypeDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a threat group/adversary. */
export type ThreatGroupTypeSoftwareArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a threat group/adversary. */
export type ThreatGroupTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreatGroupTypeConnection = {
  __typename?: 'ThreatGroupTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ThreatGroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ThreatGroupType` and its cursor. */
export type ThreatGroupTypeEdge = {
  __typename?: 'ThreatGroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ThreatGroupType>;
};

export type ThreatGroupsThreatSummary = {
  __typename?: 'ThreatGroupsThreatSummary';
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  coverage?: Maybe<Scalars['Int']['output']>;
  severityDistribution?: Maybe<Array<Maybe<SeverityThreatSummary>>>;
};

/** An enumeration. */
export enum ThreatProfileCountries {
  Abw = 'ABW',
  Afg = 'AFG',
  Afghanistan = 'AFGHANISTAN',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  AlandIslands = 'ALAND_ISLANDS',
  Alb = 'ALB',
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  AmericanSamoa = 'AMERICAN_SAMOA',
  And = 'AND',
  Andorra = 'ANDORRA',
  Angola = 'ANGOLA',
  Anguilla = 'ANGUILLA',
  Antarctica = 'ANTARCTICA',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Are = 'ARE',
  Arg = 'ARG',
  Argentina = 'ARGENTINA',
  Arm = 'ARM',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Australia = 'AUSTRALIA',
  Austria = 'AUSTRIA',
  Aut = 'AUT',
  Aze = 'AZE',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Barbados = 'BARBADOS',
  Bdi = 'BDI',
  Bel = 'BEL',
  Belarus = 'BELARUS',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Ben = 'BEN',
  Benin = 'BENIN',
  Bermuda = 'BERMUDA',
  Bes = 'BES',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bhutan = 'BHUTAN',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bolivia = 'BOLIVIA',
  BonaireSintEustatiusAndSaba = 'BONAIRE_SINT_EUSTATIUS_AND_SABA',
  BosniaAndHerzegovina = 'BOSNIA_AND_HERZEGOVINA',
  Botswana = 'BOTSWANA',
  BouvetIsland = 'BOUVET_ISLAND',
  Bra = 'BRA',
  Brazil = 'BRAZIL',
  Brb = 'BRB',
  BritishIndianOceanTerritory = 'BRITISH_INDIAN_OCEAN_TERRITORY',
  BritishVirginIslands = 'BRITISH_VIRGIN_ISLANDS',
  Brn = 'BRN',
  BruneiDarussalam = 'BRUNEI_DARUSSALAM',
  Btn = 'BTN',
  Bulgaria = 'BULGARIA',
  BurkinaFaso = 'BURKINA_FASO',
  Burundi = 'BURUNDI',
  Bvt = 'BVT',
  Bwa = 'BWA',
  CaboVerde = 'CABO_VERDE',
  Caf = 'CAF',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  Can = 'CAN',
  Canada = 'CANADA',
  CaymanIslands = 'CAYMAN_ISLANDS',
  Cck = 'CCK',
  CentralAfricanRepublic = 'CENTRAL_AFRICAN_REPUBLIC',
  Chad = 'CHAD',
  Che = 'CHE',
  Chile = 'CHILE',
  China = 'CHINA',
  ChinaHongKongSpecialAdministrativeRegion = 'CHINA_HONG_KONG_SPECIAL_ADMINISTRATIVE_REGION',
  ChinaMacaoSpecialAdministrativeRegion = 'CHINA_MACAO_SPECIAL_ADMINISTRATIVE_REGION',
  Chl = 'CHL',
  Chn = 'CHN',
  ChristmasIsland = 'CHRISTMAS_ISLAND',
  Civ = 'CIV',
  Cmr = 'CMR',
  CocosKeelingIslands = 'COCOS_KEELING_ISLANDS',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Colombia = 'COLOMBIA',
  Com = 'COM',
  Comoros = 'COMOROS',
  Congo = 'CONGO',
  CookIslands = 'COOK_ISLANDS',
  CostaRica = 'COSTA_RICA',
  CoteDivoire = 'COTE_DIVOIRE',
  Cpv = 'CPV',
  Cri = 'CRI',
  Croatia = 'CROATIA',
  Cub = 'CUB',
  Cuba = 'CUBA',
  Curacao = 'CURACAO',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cyprus = 'CYPRUS',
  Cze = 'CZE',
  Czechia = 'CZECHIA',
  DemocraticPeoplesRepublicOfKorea = 'DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA',
  DemocraticRepublicOfTheCongo = 'DEMOCRATIC_REPUBLIC_OF_THE_CONGO',
  Denmark = 'DENMARK',
  Deu = 'DEU',
  Dji = 'DJI',
  Djibouti = 'DJIBOUTI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dominica = 'DOMINICA',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  Dza = 'DZA',
  Ecu = 'ECU',
  Ecuador = 'ECUADOR',
  Egy = 'EGY',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  EquatorialGuinea = 'EQUATORIAL_GUINEA',
  Eri = 'ERI',
  Eritrea = 'ERITREA',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Estonia = 'ESTONIA',
  Eswatini = 'ESWATINI',
  Eth = 'ETH',
  Ethiopia = 'ETHIOPIA',
  FalklandIslands = 'FALKLAND_ISLANDS',
  FaroeIslands = 'FAROE_ISLANDS',
  Fiji = 'FIJI',
  Fin = 'FIN',
  Finland = 'FINLAND',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  France = 'FRANCE',
  FrenchGuiana = 'FRENCH_GUIANA',
  FrenchPolynesia = 'FRENCH_POLYNESIA',
  FrenchSouthernTerritories = 'FRENCH_SOUTHERN_TERRITORIES',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gabon = 'GABON',
  Gambia = 'GAMBIA',
  Gbr = 'GBR',
  Geo = 'GEO',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  Ggy = 'GGY',
  Gha = 'GHA',
  Ghana = 'GHANA',
  Gib = 'GIB',
  Gibraltar = 'GIBRALTAR',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Guernsey = 'GUERNSEY',
  Guf = 'GUF',
  Guinea = 'GUINEA',
  GuineaBissau = 'GUINEA_BISSAU',
  Gum = 'GUM',
  Guy = 'GUY',
  Guyana = 'GUYANA',
  Haiti = 'HAITI',
  HeardIslandAndMcdonaldIslands = 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  HolySee = 'HOLY_SEE',
  Honduras = 'HONDURAS',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  Iot = 'IOT',
  Iran = 'IRAN',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  IsleOfMan = 'ISLE_OF_MAN',
  Isr = 'ISR',
  Israel = 'ISRAEL',
  Ita = 'ITA',
  Italy = 'ITALY',
  Jam = 'JAM',
  Jamaica = 'JAMAICA',
  Japan = 'JAPAN',
  Jersey = 'JERSEY',
  Jey = 'JEY',
  Jor = 'JOR',
  Jordan = 'JORDAN',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Kazakhstan = 'KAZAKHSTAN',
  Ken = 'KEN',
  Kenya = 'KENYA',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kiribati = 'KIRIBATI',
  Kna = 'KNA',
  Kor = 'KOR',
  Kuwait = 'KUWAIT',
  Kwt = 'KWT',
  Kyrgyzstan = 'KYRGYZSTAN',
  Lao = 'LAO',
  LaoPeoplesDemocraticRepublic = 'LAO_PEOPLES_DEMOCRATIC_REPUBLIC',
  Latvia = 'LATVIA',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lebanon = 'LEBANON',
  Lesotho = 'LESOTHO',
  Liberia = 'LIBERIA',
  Libya = 'LIBYA',
  Lie = 'LIE',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Luxembourg = 'LUXEMBOURG',
  Lva = 'LVA',
  Mac = 'MAC',
  Madagascar = 'MADAGASCAR',
  Maf = 'MAF',
  Malawi = 'MALAWI',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Mali = 'MALI',
  Malta = 'MALTA',
  Mar = 'MAR',
  MarshallIslands = 'MARSHALL_ISLANDS',
  Martinique = 'MARTINIQUE',
  Mauritania = 'MAURITANIA',
  Mauritius = 'MAURITIUS',
  Mayotte = 'MAYOTTE',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mexico = 'MEXICO',
  Mhl = 'MHL',
  Micronesia = 'MICRONESIA',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Monaco = 'MONACO',
  Mongolia = 'MONGOLIA',
  Montenegro = 'MONTENEGRO',
  Montserrat = 'MONTSERRAT',
  Morocco = 'MOROCCO',
  Moz = 'MOZ',
  Mozambique = 'MOZAMBIQUE',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Myanmar = 'MYANMAR',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Namibia = 'NAMIBIA',
  Nauru = 'NAURU',
  Ncl = 'NCL',
  Nepal = 'NEPAL',
  Ner = 'NER',
  Netherlands = 'NETHERLANDS',
  NewCaledonia = 'NEW_CALEDONIA',
  NewZealand = 'NEW_ZEALAND',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Nicaragua = 'NICARAGUA',
  Niger = 'NIGER',
  Nigeria = 'NIGERIA',
  Niu = 'NIU',
  Niue = 'NIUE',
  Nld = 'NLD',
  Nor = 'NOR',
  NorfolkIsland = 'NORFOLK_ISLAND',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  NorthMacedonia = 'NORTH_MACEDONIA',
  Norway = 'NORWAY',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Oman = 'OMAN',
  Omn = 'OMN',
  Pak = 'PAK',
  Pakistan = 'PAKISTAN',
  Palau = 'PALAU',
  Pan = 'PAN',
  Panama = 'PANAMA',
  PapuaNewGuinea = 'PAPUA_NEW_GUINEA',
  Paraguay = 'PARAGUAY',
  Pcn = 'PCN',
  Per = 'PER',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Phl = 'PHL',
  Pitcairn = 'PITCAIRN',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  PuertoRico = 'PUERTO_RICO',
  Pyf = 'PYF',
  Qat = 'QAT',
  Qatar = 'QATAR',
  RepublicOfKorea = 'REPUBLIC_OF_KOREA',
  RepublicOfMoldova = 'REPUBLIC_OF_MOLDOVA',
  Reu = 'REU',
  Reunion = 'REUNION',
  Romania = 'ROMANIA',
  Rou = 'ROU',
  Rus = 'RUS',
  RussianFederation = 'RUSSIAN_FEDERATION',
  Rwa = 'RWA',
  Rwanda = 'RWANDA',
  SaintBarthelemy = 'SAINT_BARTHELEMY',
  SaintHelena = 'SAINT_HELENA',
  SaintKittsAndNevis = 'SAINT_KITTS_AND_NEVIS',
  SaintLucia = 'SAINT_LUCIA',
  SaintMartin = 'SAINT_MARTIN',
  SaintPierreAndMiquelon = 'SAINT_PIERRE_AND_MIQUELON',
  SaintVincentAndTheGrenadines = 'SAINT_VINCENT_AND_THE_GRENADINES',
  Samoa = 'SAMOA',
  SanMarino = 'SAN_MARINO',
  SaoTomeAndPrincipe = 'SAO_TOME_AND_PRINCIPE',
  Sark = 'SARK',
  Sau = 'SAU',
  SaudiArabia = 'SAUDI_ARABIA',
  Sdn = 'SDN',
  Sen = 'SEN',
  Senegal = 'SENEGAL',
  Serbia = 'SERBIA',
  Seychelles = 'SEYCHELLES',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Shn = 'SHN',
  SierraLeone = 'SIERRA_LEONE',
  Singapore = 'SINGAPORE',
  SintMaarten = 'SINT_MAARTEN',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  Slv = 'SLV',
  Smr = 'SMR',
  SolomonIslands = 'SOLOMON_ISLANDS',
  Som = 'SOM',
  Somalia = 'SOMALIA',
  SouthAfrica = 'SOUTH_AFRICA',
  SouthGeorgiaAndTheSouthSandwichIslands = 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS',
  SouthSudan = 'SOUTH_SUDAN',
  Spain = 'SPAIN',
  Spm = 'SPM',
  Srb = 'SRB',
  SriLanka = 'SRI_LANKA',
  Ssd = 'SSD',
  StateOfPalestine = 'STATE_OF_PALESTINE',
  Stp = 'STP',
  Sudan = 'SUDAN',
  Sur = 'SUR',
  Suriname = 'SURINAME',
  SvalbardAndJanMayenIslands = 'SVALBARD_AND_JAN_MAYEN_ISLANDS',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  SyrianArabRepublic = 'SYRIAN_ARAB_REPUBLIC',
  Taiwan = 'TAIWAN',
  Tajikistan = 'TAJIKISTAN',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Thailand = 'THAILAND',
  TimorLeste = 'TIMOR_LESTE',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Togo = 'TOGO',
  Tokelau = 'TOKELAU',
  Ton = 'TON',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tto = 'TTO',
  Tun = 'TUN',
  Tunisia = 'TUNISIA',
  Tur = 'TUR',
  Turkey = 'TURKEY',
  Turkmenistan = 'TURKMENISTAN',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  Tuv = 'TUV',
  Tuvalu = 'TUVALU',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Uganda = 'UGANDA',
  Ukr = 'UKR',
  Ukraine = 'UKRAINE',
  Umi = 'UMI',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedRepublicOfTanzania = 'UNITED_REPUBLIC_OF_TANZANIA',
  UnitedStatesMinorOutlyingIslands = 'UNITED_STATES_MINOR_OUTLYING_ISLANDS',
  UnitedStatesOfAmerica = 'UNITED_STATES_OF_AMERICA',
  UnitedStatesVirginIslands = 'UNITED_STATES_VIRGIN_ISLANDS',
  Uruguay = 'URUGUAY',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Uzbekistan = 'UZBEKISTAN',
  Vanuatu = 'VANUATU',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Venezuela = 'VENEZUELA',
  Vgb = 'VGB',
  Vietnam = 'VIETNAM',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  WallisAndFutunaIslands = 'WALLIS_AND_FUTUNA_ISLANDS',
  WesternSahara = 'WESTERN_SAHARA',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Yemen = 'YEMEN',
  Zaf = 'ZAF',
  Zambia = 'ZAMBIA',
  Zimbabwe = 'ZIMBABWE',
  Zmb = 'ZMB',
  Zwe = 'ZWE'
}

/** An enumeration. */
export enum ThreatProfileDataTypes {
  Cpi = 'CPI',
  Cui = 'CUI',
  Ferpa = 'FERPA',
  Fisma = 'FISMA',
  Glba = 'GLBA',
  Ip = 'IP',
  Itar = 'ITAR',
  Le = 'LE',
  Pci = 'PCI',
  Phi = 'PHI',
  Pii = 'PII',
  Ssn = 'SSN'
}

/** An enumeration. */
export enum ThreatProfileDomains {
  Enterprise = 'ENTERPRISE',
  Ics = 'ICS',
  Mobile = 'MOBILE'
}

/** An enumeration. */
export enum ThreatProfileGrcTypes {
  Cis = 'CIS',
  Cmmc = 'CMMC',
  Fedramp = 'FEDRAMP',
  Ferpa = 'FERPA',
  HipaaHitech = 'HIPAA_HITECH',
  Iso_27001 = 'ISO_27001',
  Iso_27017 = 'ISO_27017',
  Iso_27018 = 'ISO_27018',
  Iso_27701 = 'ISO_27701',
  NercSip = 'NERC_SIP',
  Nist_800_53 = 'NIST_800_53',
  PciDss = 'PCI_DSS',
  Soc2 = 'SOC2'
}

/** An enumeration. */
export enum ThreatProfileIndustries {
  AccommodationAndFoodServices = 'ACCOMMODATION_AND_FOOD_SERVICES',
  AdministrativeAndSupportAndWasteManagementAndRemediationServices = 'ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES',
  Aerospace = 'AEROSPACE',
  AgricultureForestryFishingAndHunting = 'AGRICULTURE_FORESTRY_FISHING_AND_HUNTING',
  ArtsEntertainmentAndRecreation = 'ARTS_ENTERTAINMENT_AND_RECREATION',
  Automotive = 'AUTOMOTIVE',
  Aviation = 'AVIATION',
  Biotechnology = 'BIOTECHNOLOGY',
  BitcoinExchanges = 'BITCOIN_EXCHANGES',
  CasinosAndGambling = 'CASINOS_AND_GAMBLING',
  Chemical = 'CHEMICAL',
  Construction = 'CONSTRUCTION',
  CriticalInfrastructure = 'CRITICAL_INFRASTRUCTURE',
  Defense = 'DEFENSE',
  Diplomats = 'DIPLOMATS',
  DiplomatsAndJournalists = 'DIPLOMATS_AND_JOURNALISTS',
  Education = 'EDUCATION',
  EducationalServices = 'EDUCATIONAL_SERVICES',
  ElectronicsAndJournalists = 'ELECTRONICS_AND_JOURNALISTS',
  Embassies = 'EMBASSIES',
  Energy = 'ENERGY',
  Engineering = 'ENGINEERING',
  Entertainment = 'ENTERTAINMENT',
  ECommerceSoftwareDevelopmentAndVideoGameDevelopment = 'E_COMMERCE_SOFTWARE_DEVELOPMENT_AND_VIDEO_GAME_DEVELOPMENT',
  FinanceAndInsurance = 'FINANCE_AND_INSURANCE',
  Financial = 'FINANCIAL',
  FoodAndAgriculture = 'FOOD_AND_AGRICULTURE',
  Gaming = 'GAMING',
  Government = 'GOVERNMENT',
  Healthcare = 'HEALTHCARE',
  HealthCareAndSocialAssistance = 'HEALTH_CARE_AND_SOCIAL_ASSISTANCE',
  HighTech = 'HIGH_TECH',
  Hospitality = 'HOSPITALITY',
  Imagery = 'IMAGERY',
  Industrial = 'INDUSTRIAL',
  Information = 'INFORMATION',
  IntelligenceAgencies = 'INTELLIGENCE_AGENCIES',
  IntelligenceOrganizations = 'INTELLIGENCE_ORGANIZATIONS',
  InternationalRelations = 'INTERNATIONAL_RELATIONS',
  It = 'IT',
  JournalistsAndSoftwareDevelopers = 'JOURNALISTS_AND_SOFTWARE_DEVELOPERS',
  Lawyers = 'LAWYERS',
  LawEnforcement = 'LAW_ENFORCEMENT',
  ManagementOfCompaniesAndEnterprises = 'MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES',
  Manufacturing = 'MANUFACTURING',
  Media = 'MEDIA',
  Mining = 'MINING',
  MiningQuarryingAndOilAndGasExtraction = 'MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION',
  Msps = 'MSPS',
  NanotechnologyNuclearResearchIslamicActivistsAndScholarsAndCompaniesDevelopingCryptographicTechnologies = 'NANOTECHNOLOGY_NUCLEAR_RESEARCH_ISLAMIC_ACTIVISTS_AND_SCHOLARS_AND_COMPANIES_DEVELOPING_CRYPTOGRAPHIC_TECHNOLOGIES',
  NavigationAndLawyers = 'NAVIGATION_AND_LAWYERS',
  Ngos = 'NGOS',
  NonProfitOrganizations = 'NON_PROFIT_ORGANIZATIONS',
  OilAndGas = 'OIL_AND_GAS',
  OnlineVideoGameCompanies = 'ONLINE_VIDEO_GAME_COMPANIES',
  OtherMaritimeRelatedTargetsAcrossMultipleVerticals = 'OTHER_MARITIME_RELATED_TARGETS_ACROSS_MULTIPLE_VERTICALS',
  OtherServicesExceptPublicAdministration = 'OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION',
  Petrochemical = 'PETROCHEMICAL',
  Pharmaceutical = 'PHARMACEUTICAL',
  PoliticalDissidentsAndForbes = 'POLITICAL_DISSIDENTS_AND_FORBES',
  PoliticalEntities = 'POLITICAL_ENTITIES',
  ProfessionalScientificAndTechnicalServices = 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
  PublicAdministration = 'PUBLIC_ADMINISTRATION',
  RealEstateAndRentalAndLeasing = 'REAL_ESTATE_AND_RENTAL_AND_LEASING',
  Research = 'RESEARCH',
  Retail = 'RETAIL',
  RetailTrade = 'RETAIL_TRADE',
  Satellites = 'SATELLITES',
  ScientificResearchCenters = 'SCIENTIFIC_RESEARCH_CENTERS',
  ShippingAndLogistics = 'SHIPPING_AND_LOGISTICS',
  Technology = 'TECHNOLOGY',
  Telecommunications = 'TELECOMMUNICATIONS',
  ThinkTanks = 'THINK_TANKS',
  Transportation = 'TRANSPORTATION',
  TransportationAndWarehousing = 'TRANSPORTATION_AND_WAREHOUSING',
  Utilities = 'UTILITIES',
  Uyghurs = 'UYGHURS',
  UyghurCommunities = 'UYGHUR_COMMUNITIES',
  WebDevelopment = 'WEB_DEVELOPMENT',
  WholesaleTrade = 'WHOLESALE_TRADE'
}

export type ThreatProfileInput = {
  /** If the threat profile is active, only one threat profile can be active at a time. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, we will automatically add platforms to the config as we find them via the IntegrationAsset parser. */
  automaticallyUpdatePlatforms?: InputMaybe<Scalars['Boolean']['input']>;
  /** The countries in which an organization operates. */
  countries?: InputMaybe<Array<InputMaybe<ThreatProfileCountries>>>;
  /** The datatypes than an organization stores. */
  datatypes?: InputMaybe<Array<InputMaybe<ThreatProfileDataTypes>>>;
  /** An optional short description of the threat profile. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The industries that an organization belongs. */
  industries?: InputMaybe<Array<InputMaybe<ThreatProfileIndustries>>>;
  /** Name of the threat profile. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A list of domains which are relevant to this threat profile. */
  relevantDomains?: InputMaybe<Array<InputMaybe<ThreatProfileDomains>>>;
  /** A list of GRC types which are relavent to this threat profile. */
  relevantGrcTypes?: InputMaybe<Array<InputMaybe<ThreatProfileGrcTypes>>>;
  /** A list of platforms which are relevant to this threat profile. */
  relevantPlatforms?: InputMaybe<Array<InputMaybe<ThreatProfilePlatforms>>>;
  /** The size of an organization. */
  size?: InputMaybe<ThreatProfileSizes>;
};

/** An enumeration. */
export enum ThreatProfilePlatforms {
  Android = 'ANDROID',
  AzureAd = 'AZURE_AD',
  Containers = 'CONTAINERS',
  ControlServer = 'CONTROL_SERVER',
  DataHistorian = 'DATA_HISTORIAN',
  DeviceConfigurationParameters = 'DEVICE_CONFIGURATION_PARAMETERS',
  EngineeringWorkstation = 'ENGINEERING_WORKSTATION',
  FieldControllerRtuPlcIed = 'FIELD_CONTROLLER_RTU_PLC_IED',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  HumanMachineInterface = 'HUMAN_MACHINE_INTERFACE',
  Iaas = 'IAAS',
  InputOutputServer = 'INPUT_OUTPUT_SERVER',
  Ios = 'IOS',
  Linux = 'LINUX',
  Macos = 'MACOS',
  Network = 'NETWORK',
  Office_365 = 'OFFICE_365',
  Pre = 'PRE',
  Saas = 'SAAS',
  SafetyInstrumentedSystemProtectionRelay = 'SAFETY_INSTRUMENTED_SYSTEM_PROTECTION_RELAY',
  Windows = 'WINDOWS'
}

/** An enumeration. */
export enum ThreatProfileSizes {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Unspecified = 'UNSPECIFIED',
  Xlarge = 'XLARGE'
}

/** A model that takes information about an organization to prioritize potential risks and threats. */
export type ThreatProfileType = Node & {
  __typename?: 'ThreatProfileType';
  /** Indicates if this threat profile is active, only one profile can be active at a time. */
  active: Scalars['Boolean']['output'];
  automaticallyUpdatePlatforms?: Maybe<Scalars['Boolean']['output']>;
  countries?: Maybe<Array<Maybe<ThreatProfileCountries>>>;
  /** When the threat model was created. */
  createdTimestamp: Scalars['DateTime']['output'];
  datatypes?: Maybe<Array<Maybe<ThreatProfileDataTypes>>>;
  defensePosture?: Maybe<Scalars['Int']['output']>;
  /** An optional short description about this threat profile. */
  description?: Maybe<Scalars['String']['output']>;
  exposureScore?: Maybe<Scalars['Int']['output']>;
  fleetExposure?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Maybe<ThreatProfileIndustries>>>;
  /** When the threat model was last modified. */
  modifiedTimestamp: Scalars['DateTime']['output'];
  /** The name of this threat profile. */
  name: Scalars['String']['output'];
  /** The threat profile being used to prioritize the technique. */
  prioritizedTechniques: PrioritizedTechniqueTypeConnection;
  relevantDomains?: Maybe<Array<Maybe<ThreatProfileDomains>>>;
  relevantGrcTypes?: Maybe<Array<Maybe<ThreatProfileGrcTypes>>>;
  relevantPlatforms?: Maybe<Array<Maybe<ThreatProfilePlatforms>>>;
  size?: Maybe<ThreatProfileSizes>;
  threatExposure?: Maybe<Scalars['Int']['output']>;
};

/** A model that takes information about an organization to prioritize potential risks and threats. */
export type ThreatProfileTypePrioritizedTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreatProfileTypeConnection = {
  __typename?: 'ThreatProfileTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ThreatProfileTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ThreatProfileType` and its cursor. */
export type ThreatProfileTypeEdge = {
  __typename?: 'ThreatProfileTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ThreatProfileType>;
};

export type ThreatSummary = {
  __typename?: 'ThreatSummary';
  assets?: Maybe<AssetsThreatSummary>;
  campaigns?: Maybe<CampaignsThreatSummary>;
  count?: Maybe<Scalars['Int']['output']>;
  countDelta?: Maybe<Scalars['Int']['output']>;
  software?: Maybe<SoftwareThreatSummary>;
  techniques?: Maybe<TechniquesThreatSummary>;
  threatGroups?: Maybe<ThreatGroupsThreatSummary>;
};

/** An enumeration. */
export enum ThreatprofilesRecommendedActionCategoryChoices {
  /** Assets */
  Assets = 'ASSETS',
  /** Control */
  Control = 'CONTROL',
  /** Detects Software */
  DetectsSoftware = 'DETECTS_SOFTWARE',
  /** Detects Technique */
  DetectsTechnique = 'DETECTS_TECHNIQUE',
  /** Detects Threat Group */
  DetectsThreatGroup = 'DETECTS_THREAT_GROUP',
  /** Telemetry */
  Telemetry = 'TELEMETRY',
  /** Vulnerability */
  Vulnerability = 'VULNERABILITY'
}

/** An enumeration. */
export enum ThreatprofilesRecommendedActionStatusChoices {
  /** Completed */
  Completed = 'COMPLETED',
  /** Ignored */
  Ignored = 'IGNORED',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Not Started */
  NotStarted = 'NOT_STARTED',
  /** Resolved through alternative mitigation */
  ResolvedThroughAlternativeMitigation = 'RESOLVED_THROUGH_ALTERNATIVE_MITIGATION',
  /** resolved through third party */
  ResolvedThroughThirdParty = 'RESOLVED_THROUGH_THIRD_PARTY',
  /** Risk Accepted */
  RiskAccepted = 'RISK_ACCEPTED',
  /** To address */
  ToAddress = 'TO_ADDRESS'
}

/** An enumeration. */
export enum ThreatsReferenceDetectionSeverityChoices {
  /** Critical */
  Critical = 'CRITICAL',
  /** High */
  High = 'HIGH',
  /** Info */
  Info = 'INFO',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

/** An enumeration. */
export enum ThreatsSoftwareSoftwareTypeChoices {
  /** Malware */
  Malware = 'MALWARE',
  /** Tool */
  Tool = 'TOOL'
}

export type TicketType = Node & {
  __typename?: 'TicketType';
  /** Represents the time at which the object was originally created, corresponds to the STIX 'created' field. */
  createdTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** A description of what the object represents. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Represents when the object was last modified, corresponds to the STIX 'modified' field. */
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the STIX object. */
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  referenceId: Scalars['String']['output'];
};

export type TicketTypeConnection = {
  __typename?: 'TicketTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TicketTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TicketType` and its cursor. */
export type TicketTypeEdge = {
  __typename?: 'TicketTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TicketType>;
};

/** An object containing an API access token for a user */
export type TokenType = {
  __typename?: 'TokenType';
  key?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type TopCampaignsSummary = {
  __typename?: 'TopCampaignsSummary';
  /** The id of this campaign. */
  id?: Maybe<Scalars['String']['output']>;
  /** The name of this campaign. */
  name?: Maybe<Scalars['String']['output']>;
};

export type TopPrioritizedPlatformsSummary = {
  __typename?: 'TopPrioritizedPlatformsSummary';
  /** The content score of one of the top Platforms at this time */
  content?: Maybe<Scalars['Int']['output']>;
  /** The id of this prioritized platform. */
  id?: Maybe<Scalars['String']['output']>;
  /** The name of this top Platform. */
  name?: Maybe<Scalars['String']['output']>;
  /** The priority score of one of the top Platforms at this time */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type TopPrioritizedSoftwareSummary = {
  __typename?: 'TopPrioritizedSoftwareSummary';
  /** The content score of one of the top Software at this time */
  content?: Maybe<Scalars['Int']['output']>;
  /** The MITRE ID of this top Software. */
  mitreId?: Maybe<Scalars['String']['output']>;
  /** The name of this top Software. */
  name?: Maybe<Scalars['String']['output']>;
  /** The priority score of one of the top Software at this time */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type TopPrioritizedTechniquesSummary = {
  __typename?: 'TopPrioritizedTechniquesSummary';
  /** The content score of one of the top Techniques at this time */
  content?: Maybe<Scalars['Int']['output']>;
  /** The MITRE ID of this top Technique. */
  mitreId?: Maybe<Scalars['String']['output']>;
  /** The name of this top Technique. */
  name?: Maybe<Scalars['String']['output']>;
  /** The priority score of one of the top Techniques at this time */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type TopPrioritizedThreatGroupsSummary = {
  __typename?: 'TopPrioritizedThreatGroupsSummary';
  /** The content score of one of the top Threat Groups at this time */
  content?: Maybe<Scalars['Int']['output']>;
  /** The MITRE ID of this top Threat Group. */
  mitreId?: Maybe<Scalars['String']['output']>;
  /** The name of this top Threat Group. */
  name?: Maybe<Scalars['String']['output']>;
  /** The priority score of one of the top Threat Groups at this time */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type TrendsType = {
  __typename?: 'TrendsType';
  /** Number of assets with a CISA known exploited vulnerability found on them. */
  assetsWithCriticalKnownExploitedVulnerabilities?: Maybe<
    Scalars['Float']['output']
  >;
  /** The change in number of assets with critical known vulnerabilities over the specified number of days. */
  assetsWithCriticalKnownExploitedVulnerabilitiesDelta?: Maybe<
    Scalars['Float']['output']
  >;
  /** A list of number of assets with critical known vulnerabilities per day. */
  assetsWithCriticalKnownExploitedVulnerabilitiesTrend?: Maybe<
    Array<Maybe<DailyTrendEntry>>
  >;
  days?: Maybe<Scalars['Int']['output']>;
  /** The percent of enabled detections. */
  detectionsEnabledPercentage?: Maybe<Scalars['Float']['output']>;
  /** The percent change of enabled detections over the specified number of days. */
  detectionsEnabledPercentageDelta?: Maybe<Scalars['Float']['output']>;
  /** A list of percent of enabled detections per day. */
  detectionsEnabledPercentageTrend?: Maybe<Array<Maybe<DailyTrendEntry>>>;
  /** The percentage of detections firing alerts. */
  detectionsFiredAlertsPercentage?: Maybe<Scalars['Float']['output']>;
  /** The change in percentage of detections firing alerts over the specified number of days. */
  detectionsFiredAlertsPercentageDelta?: Maybe<Scalars['Float']['output']>;
  /** A list of percentages of detections firing alerts per day. */
  detectionsFiredAlertsPercentageTrend?: Maybe<Array<Maybe<DailyTrendEntry>>>;
  /** Average content score for campaigns in the last 30 days by priority. */
  recentCampaignContent?: Maybe<Scalars['Float']['output']>;
  /** The change in recent campaign content score over the specified number of days. */
  recentCampaignContentDelta?: Maybe<Scalars['Float']['output']>;
  /** A list of recent campaign content scores per day. */
  recentCampaignContentTrend?: Maybe<Array<Maybe<DailyTrendEntry>>>;
  /** The number of campaigns in the last 30 days exploiting vulnerabilities found on one or more assets. */
  recentCampaignsExploitingVulnerabilities?: Maybe<Scalars['Float']['output']>;
  /** The change in the number of campaigns exploiting vulnerabilities over the specified number of days. */
  recentCampaignsExploitingVulnerabilitiesDelta?: Maybe<
    Scalars['Float']['output']
  >;
  /** A list of number of campaigns exploiting vulnerabilities per day. */
  recentCampaignsExploitingVulnerabilitiesTrend?: Maybe<
    Array<Maybe<DailyTrendEntry>>
  >;
  /** The percentage of tracked telemetry subcategories being logged. */
  telemetrySubcategoriesCollectedPercentage?: Maybe<Scalars['Float']['output']>;
  /** The change in percentage of tracked telemetry subcategories being logged over the specified number of days. */
  telemetrySubcategoriesCollectedPercentageDelta?: Maybe<
    Scalars['Float']['output']
  >;
  /** A list of telemetry subcategories collected per day. */
  telemetrySubcategoriesCollectedPercentageTrend?: Maybe<
    Array<Maybe<DailyTrendEntry>>
  >;
  /** Average content score for the top 10 techniques by priority. */
  topTechniqueContent?: Maybe<Scalars['Float']['output']>;
  /** The change in the top technique content score over the specified number of days. */
  topTechniqueContentDelta?: Maybe<Scalars['Float']['output']>;
  /** A list of top technique content scores per day. */
  topTechniqueContentTrend?: Maybe<Array<Maybe<DailyTrendEntry>>>;
  /** Average content score for the top 10 threat groups by priority. */
  topThreatGroupContent?: Maybe<Scalars['Float']['output']>;
  /** The change in the top threat group content score over the specified number of days. */
  topThreatGroupContentDelta?: Maybe<Scalars['Float']['output']>;
  /** A list of top threat group content scores per day. */
  topThreatGroupContentTrend?: Maybe<Array<Maybe<DailyTrendEntry>>>;
  /** The number of unique vulnerabilities actively being used in campaigns found on one or more assets. */
  vulnerabilitiesActivelyUsedInCampaigns?: Maybe<Scalars['Float']['output']>;
  /** The change in number of unique vulnerabilities actively being used in campaigns over the specified number of days. */
  vulnerabilitiesActivelyUsedInCampaignsDelta?: Maybe<
    Scalars['Float']['output']
  >;
  /** A list of number of unique vulnerabilities actively being used in campaigns per day. */
  vulnerabilitiesActivelyUsedInCampaignsTrend?: Maybe<
    Array<Maybe<DailyTrendEntry>>
  >;
};

export type UpdateChildTenant = {
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  tenantId: Scalars['String']['input'];
};

export type UpdateCurrentUser = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  forceReset?: InputMaybe<Scalars['Boolean']['input']>;
  hasAgreedToTerms?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroup = {
  groupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roleIds: Array<Scalars['String']['input']>;
};

/** Updates an information model. */
export type UpdateInformationModel = {
  __typename?: 'UpdateInformationModel';
  informationModel?: Maybe<InformationModelType>;
};

/** Updates an information model alias. */
export type UpdateInformationModelAlias = {
  __typename?: 'UpdateInformationModelAlias';
  alias?: Maybe<InformationModelAliasType>;
};

export type UpdateInformationModelAliasInput = {
  /** Name of the field in Interpres. */
  fieldName: Scalars['String']['input'];
  /** Name of the corresponding field in the integration. */
  newFieldName: Scalars['String']['input'];
};

/** Updates an integration. */
export type UpdateIntegration = {
  __typename?: 'UpdateIntegration';
  integration?: Maybe<IntegrationType>;
};

export type UpdateIntegrationInputType = {
  /** The arguments based on the integration types parameters and the intervals for the reoccuring actions of the integration. */
  config?: InputMaybe<Scalars['String']['input']>;
  /** A brief description of the integration. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Enables this integration. */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Environment to run the integration. */
  environment?: InputMaybe<Scalars['String']['input']>;
  /** The information model used for this integration when identifying telemetry. */
  informationModelId?: InputMaybe<Scalars['ID']['input']>;
  modes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Name of the integration. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a recommended action. */
export type UpdateIntegrationRecommendedAction = {
  __typename?: 'UpdateIntegrationRecommendedAction';
  recommendedAction?: Maybe<IntegrationRecommendedActionType>;
};

/** Updates an integration tag. */
export type UpdateIntegrationTag = {
  __typename?: 'UpdateIntegrationTag';
  tag?: Maybe<IntegrationTagType>;
};

/** Updates a recommended action. */
export type UpdateRecommendedAction = {
  __typename?: 'UpdateRecommendedAction';
  recommendedAction?: Maybe<RecommendedActionType>;
};

/** Updates a threat profile. */
export type UpdateThreatProfile = {
  __typename?: 'UpdateThreatProfile';
  threatProfile?: Maybe<ThreatProfileType>;
};

export type UpdateUser = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  forceReset?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  createdTimestamp: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groups: Array<Maybe<Group>>;
  hasAgreedToTerms: Scalars['Boolean']['output'];
  imageUrl: Scalars['String']['output'];
  lastLoginTimestamp: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

/** User profile object. */
export type UserProfileType = {
  __typename?: 'UserProfileType';
  /** An object that stores UI metadata. */
  metadata?: Maybe<Scalars['GenericScalar']['output']>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

/** User object. */
export type UserType = Node & {
  __typename?: 'UserType';
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  userProfile?: Maybe<UserProfileType>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output'];
};

export type VulnerabilityConnection = {
  __typename?: 'VulnerabilityConnection';
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VulnerabilityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `Vulnerability` and its cursor. */
export type VulnerabilityEdge = {
  __typename?: 'VulnerabilityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VulnerabilityType>;
};

/** An object representing a vulnerability. */
export type VulnerabilityType = Node &
  StixObject & {
    __typename?: 'VulnerabilityType';
    affectedProducts: AffectedProductTypeConnection;
    /** Get a filterable list of threat groups, referred to in the Interpres UI as adversaries. */
    assets?: Maybe<AssetTypeConnection>;
    assetsCount?: Maybe<Scalars['Int']['output']>;
    /** A value representing the number of sources mentioning this CVE. */
    awarenessLevel?: Maybe<Scalars['Int']['output']>;
    /** Known vulnerabilities/CVEs used during this campaign. */
    campaigns: CampaignTypeConnection;
    configuration?: Maybe<Scalars['GenericScalar']['output']>;
    /** Affected software configurations. */
    configurations: Scalars['JSONString']['output'];
    /** The Common Vulnerabilities and Exposures (CVE) unique identifier. */
    cveId: Scalars['String']['output'];
    /** The Common Vulnerability Scoring System (CVSS) score. */
    cvssSeverityScore?: Maybe<Scalars['Float']['output']>;
    /** The Common Vulnerability Scoring System (CVSS) score version. */
    cvssSeverityScoreVersion: Scalars['String']['output'];
    /** Description of the STIX object. */
    description?: Maybe<Scalars['String']['output']>;
    /** Exploit Prediction Scoring System (EPSS) provided exploitability percentile. */
    epssExploitabilityPercentile?: Maybe<Scalars['Float']['output']>;
    /** Exploit Prediction Scoring System (EPSS) provided exploitability score. */
    epssExploitabilityScore?: Maybe<Scalars['Float']['output']>;
    id: Scalars['ID']['output'];
    /** Name of the STIX object. */
    name: Scalars['String']['output'];
    /** National Institute of Standards and Technology (NIST) provided exploitability score. */
    nistExploitabilityScore?: Maybe<Scalars['Float']['output']>;
    /** National Institute of Standards and Technology (NIST) provided impact score. */
    nistImpactScore?: Maybe<Scalars['Float']['output']>;
    /** Platforms potentially affected by this vulnerability. */
    platforms: PlatformTypeConnection;
    /** Structured Threat Information eXpression ID. */
    stixId: Scalars['String']['output'];
    /** The techniques affected by this vulnerability, e.g. Exploit Public-Facing Application. */
    techniques: TechniqueTypeConnection;
    /** A short title describing the vulnerability. */
    title?: Maybe<Scalars['String']['output']>;
    /** A value representing how much an organization should prioritize this technique based on their threat profile. This value does not take existing protections into consideration. */
    universalPriority: Scalars['Int']['output'];
  };

/** An object representing a vulnerability. */
export type VulnerabilityTypeAffectedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a vulnerability. */
export type VulnerabilityTypeAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  critical?: InputMaybe<Scalars['Boolean']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  exposure?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  hostname_Icontains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identityDepartments?: InputMaybe<Scalars['String']['input']>;
  identityEmailDomain?: InputMaybe<Scalars['String']['input']>;
  identityGroups?: InputMaybe<Scalars['String']['input']>;
  identityLabels?: InputMaybe<Scalars['String']['input']>;
  identityLocations?: InputMaybe<Scalars['String']['input']>;
  identityRoles?: InputMaybe<Scalars['String']['input']>;
  integrationNames?: InputMaybe<Scalars['String']['input']>;
  integrationNamesAndOr?: InputMaybe<IntegrationNamesAndOrFilterInput>;
  isVulnerable?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Scalars['String']['input']>;
  labelsV2?: InputMaybe<LabelFilterInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  operatingSystem_Icontains?: InputMaybe<Scalars['String']['input']>;
  operatingSystems?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platformNames?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  scannerIds?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchNamesOrDescription?: InputMaybe<Scalars['String']['input']>;
  vulnerabilityCveIds?: InputMaybe<Scalars['String']['input']>;
};

/** An object representing a vulnerability. */
export type VulnerabilityTypeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a vulnerability. */
export type VulnerabilityTypePlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An object representing a vulnerability. */
export type VulnerabilityTypeTechniquesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VulnerabilityTypeConnection = {
  __typename?: 'VulnerabilityTypeConnection';
  /** Number of nodes returned. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VulnerabilityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes matching query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VulnerabilityType` and its cursor. */
export type VulnerabilityTypeEdge = {
  __typename?: 'VulnerabilityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VulnerabilityType>;
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'ActivityFeedEntryType',
      'ActivityFeedLinkType',
      'AdminLogType',
      'AffectedProductType',
      'AffectedProductVersionType',
      'AssetApplicationType',
      'AssetIPType',
      'AssetLabelType',
      'AssetType',
      'AssetVulnerabilityType',
      'CampaignType',
      'ChatBotHistoryType',
      'ControlType',
      'DailyTrendDataType',
      'DefinitionSourceType',
      'DetectionSoftwareSuggestionType',
      'DetectionSoftwareUserMappingType',
      'DetectionSoftwareVendorMappingType',
      'DetectionTechniqueSuggestionType',
      'DetectionTechniqueUserMappingType',
      'DetectionTechniqueVendorMappingType',
      'DetectionThreatGroupSuggestionType',
      'DetectionThreatGroupUserMappingType',
      'DetectionThreatGroupVendorMappingType',
      'DetectionType',
      'DetectionTypeType',
      'DomainType',
      'IdentifierTypeType',
      'IdentityLabelType',
      'IdentityType',
      'InformationModelAliasType',
      'InformationModelType',
      'IntegrationAssetType',
      'IntegrationIdentityType',
      'IntegrationModeType',
      'IntegrationRecommendedActionType',
      'IntegrationTagType',
      'IntegrationType',
      'IntegrationTypeType',
      'PlatformType',
      'PolicyRuleType',
      'PolicyType',
      'PrioritizedCampaignType',
      'PrioritizedPlatformType',
      'PrioritizedSoftwareType',
      'PrioritizedTechniqueType',
      'PrioritizedThreatGroupType',
      'PrioritizedVulnerabilityType',
      'PrivateCampaignType',
      'RecommendedActionType',
      'ReferenceDetectionType',
      'SoftwareType',
      'TacticType',
      'TechniqueTelemetrySubcategoryRelationshipType',
      'TechniqueType',
      'TelemetryCategoryType',
      'TelemetryIdentifierType',
      'TelemetrySubcategoryType',
      'ThreatGroupType',
      'ThreatProfileType',
      'TicketType',
      'UserType',
      'VulnerabilityType'
    ],
    STIXObject: [
      'AssetType',
      'CampaignType',
      'ControlType',
      'DetectionType',
      'IdentityType',
      'IntegrationAssetType',
      'IntegrationIdentityType',
      'PrioritizedCampaignType',
      'PrivateCampaignType',
      'ReferenceDetectionType',
      'SoftwareType',
      'TechniqueType',
      'TelemetrySubcategoryType',
      'ThreatGroupType',
      'VulnerabilityType'
    ],
    SearchResultUnion: [
      'AssetType',
      'DetectionType',
      'IdentityType',
      'PrioritizedCampaignType',
      'PrioritizedSoftwareType',
      'PrioritizedTechniqueType',
      'PrioritizedThreatGroupType',
      'PrioritizedVulnerabilityType',
      'TelemetrySubcategoryType'
    ]
  }
};
export default result;
